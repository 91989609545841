import React, { useState, useCallback } from "react";

import { MyCFooter } from "./footer";
import { FcAndroidOs } from "react-icons/fc";
import { GiPriceTag } from "react-icons/gi";
import { BsFillInfoCircleFill, BsBookmarkHeart } from "react-icons/bs";
import {
  MdOutlineCategory,
  MdUpdate,
  MdThumbUp,
  MdThumbDown,
  MdGetApp,
  MdExplore,
} from "react-icons/md";
import {
  RiShieldFlashFill,
  RiCameraLensFill,
  RiShieldCheckFill,
} from "react-icons/ri";
import "../fullstory.css";
import "../comments.css";
import $ from "jquery";
import ImageViewer from "react-simple-image-viewer";
import TakeComment from "../widget/takeComment";
import CommentListWidget from "../widget/commentListWidget";
import ItemWidget from "../widget/itemWidget";

class ItemView extends React.Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".root").css("display", "table");
    });
  }
  render() {
    return (
      <>
        <SpeedBar />
        <Article />
        <SimilarBlock />
        <MyCFooter />
        <div className="background bg-style-2">
          <i className="bg-circle-purple"></i>
          <i className="bg-circle-yellow bgc-1"></i>
          <i className="bg-circle-yellow bgc-2"></i>
          <i className="bg-circle-green"></i>
          <i className="bg-clouds">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2808 800"
              width="2808"
              height="800"
            >
              <path
                className="cloud-c1"
                d="M2066,500h-2a50,50,0,0,1,0-100h2a50,50,0,0,1,0,100Zm459.27,100H2758a50,50,0,0,1,0,100H2577.58a50,50,0,0,0,0,100H2126.45a50,50,0,1,0-.06-100H1970a50,50,0,0,1,0-100h232.45a50,50,0,1,0-.08-100H2186a50,50,0,0,1,0-100h104.46a50,50,0,0,0,0-100H2282a50,50,0,0,1,0-100h428a50,50,0,0,1,0,100H2601.55a50,50,0,0,0-.3,100H2606a50,50,0,0,1,0,100h-80.45a50,50,0,1,0-.28,100Z"
              ></path>
              <path
                className="cloud-c2"
                d="M2142,476H1969.55a50,50,0,0,0,0,100H1998a50,50,0,0,1,0,100H1770a50,50,0,0,1,0-100h14.26a50,50,0,1,0,0-100H1718a50,50,0,0,1,0-100h424a50,50,0,0,1,0,100Zm132,200H2170a50,50,0,0,1,0-100h104a50,50,0,0,1,0,100Z"
              ></path>
              <path
                className="cloud-c1"
                d="M962,700H781.58a50,50,0,1,0,0,100H206.45a50,50,0,0,0-.06-100H50a50,50,0,0,1,0-100H282.45a50,50,0,1,0-.08-100H266a50,50,0,0,1,0-100H370.46a50,50,0,1,0,0-100H362a50,50,0,0,1,0-100h8.46a50,50,0,1,0,0-100h-24a50,50,0,0,1,0-100H590a50,50,0,0,1,0,100H509.55a50,50,0,0,0,.08,100H790a50,50,0,0,1,0,100H681.55a50,50,0,0,0-.3,100H686a50,50,0,0,1,0,100H605.55a50,50,0,0,0-.28,100H962a50,50,0,0,1,0,100Zm168,0h-28a50,50,0,0,1,0-100h28a50,50,0,0,1,0,100Z"
              ></path>
              <path
                className="cloud-c2"
                d="M1086,556H913.57a50,50,0,0,0,0,100H962a50,50,0,0,1,0,100H654a50,50,0,0,1,0-100h28a50,50,0,0,0,0-100H642a50,50,0,0,1,0-100h444a50,50,0,0,1,0,100Z"
              ></path>
            </svg>
          </i>
        </div>
      </>
    );
  }
}

function SimilarBlock() {
  return (
    <div className="wrp section section-related">
      <div className="section-head">
        <h3 className="section-title">
          <i className="s-blue c-icon">
            <MdExplore size={24} />
          </i>
          Similar
        </h3>
        <a className="btn s-green btn-all" href="/en/android/igry/">
          <span>All games</span>
        </a>
      </div>
      <div className="entry-list list-c6">
        <SimilarEntery />
      </div>
    </div>
  );
}

function SimilarEntery() {
  let itemList = [];
  for (let i = 0; i < 6; i++) {
    itemList.push(
      <ItemWidget
        key={i}
        data={{
          key: i,
          title: "Combat Quest - Archer Action RPG",
          description: "description",
          new: true,
          update: false,
          image: "/uploads/posts/2.webp",
          link: "/",
          android: "5.0",
          version: "1.0",
          categorie: "Action",
          status: "MOD",
        }}
      />
    );
  }
  return itemList;
}

function Article() {
  return (
    <article
      className="view-app"
      itemScope
      itemType="http://schema.org/MobileApplication"
    >
      <ViewAppHead />
      <ViewMinList />
    </article>
  );
}

function ViewAppHead() {
  return (
    <div className="view-app-head dark-head dark-section">
      <div className="wrp-min">
        <h1 itemProp="name" className="title">
          Dead Cells
        </h1>
        <AppMain />
        <ScreenShortWidget />
      </div>
      <div
        className="background"
        style={{
          display: "none",
        }}
      >
        <div className="bg-img-blur">
          <i className="fit-cover">
            <img
              src="/uploads/posts/2.webp"
              alt="Dead Cells"
              width="10"
              height="10"
              loading="lazy"
            />
          </i>
        </div>
        <i className="bg-circle-green"></i>
        <i className="bg-clouds">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2550 700"
            width="2550"
            height="700"
          >
            <path
              fill="#142636"
              d="M554,100h-4A50,50,0,0,1,550,0h4a50,50,0,0,1,0,100Zm25,600H50a50,50,0,0,1,0-100h78.08a50,50,0,1,0,.11-100H98a50,50,0,0,1,0-100H238a50,50,0,0,0,0-100H194a50,50,0,0,1,0-100h56a50,50,0,0,0,.3-100H238A50,50,0,0,1,238,0H430a50,50,0,0,1,0,100H413.55a50,50,0,1,0,.05,100H630a50,50,0,0,1,0,100H521.55a50,50,0,1,0,0,100H526a50,50,0,0,1,0,100H445.55a50,50,0,0,0,.08,100H579a50,50,0,0,1,0,100Z"
            ></path>
            <path
              fill="#142636"
              d="M2073,700a50,50,0,0,1,0-100h16.43a50,50,0,0,0,0-100H1901a50,50,0,0,1,0-100h216.42a50,50,0,0,0,0-100H2097a50,50,0,0,1-50-50h0a50,50,0,0,1,50-50h252a50,50,0,0,1,0,100h-28a50,50,0,0,0,0,100h24a50,50,0,0,1,0,100h-24a50,50,0,0,0,0,100h179a50,50,0,0,1,0,100Z"
            ></path>
          </svg>
        </i>
      </div>
    </div>
  );
}

function AppMain() {
  return (
    <div className="view-app-main ignore-select">
      <AppImage />
      <AppDetail />
    </div>
  );
}

function AppDetail() {
  return (
    <div className="view-app-data">
      <SpacesList />
      <AppRate />
      <ButonGrup />
    </div>
  );
}

function ButonGrup() {
  return (
    <div className="btn-group">
      <a className="btn btn-lg btn-icon s-green anchor" href="#download-block">
        <MdGetApp size={24} />
        Download
      </a>
      <button className="btn btn-lg btn-border needreg-btn">
        <span>Request for update</span>
      </button>
    </div>
  );
}

function AppRate() {
  return (
    <div className="view-app-rate">
      <div className="likes">
        <a href="/">
          <span className="like-plus">
            <MdThumbUp size={24} />+<span id="likes-id-11708">15894</span>
          </span>
          <span className="sr-only">Like</span>
        </a>
        <a href="/">
          <span className="like-minus">
            <MdThumbDown size={24} />-<span id="dislikes-id-11708">7843</span>
            <span className="sr-only">Dislike</span>
          </span>
        </a>
      </div>
      <ul className="rate-nums muted">
        <li>
          Votes: <span id="vote-num-id-11708">23737</span>
        </li>
        <li>Comments: 34</li>
      </ul>
      <div
        className="popularity"
        itemProp="aggregateRating"
        itemScope=""
        itemType="http://schema.org/AggregateRating"
      >
        <div className="popularity-number">
          <div className="rating_status">
            <div className="rating_progress_bar" title="Application popularity">
              <b>
                <meta itemProp="ratingValue" content="67" />
                Popularity 67%
              </b>
              <meta itemProp="bestRating" content="100" />
              <span
                style={{
                  width: "67%",
                }}
              >
                67%
              </span>
            </div>
          </div>
        </div>
        <meta itemProp="reviewCount" content="23737" />
      </div>
    </div>
  );
}

function SpacesList() {
  return (
    <div className="specs-list">
      <ul>
        <li className="specs-item">
          <i className="spec-icon c-green">
            <MdUpdate size={24} />
          </i>
          <span className="spec-label">Updated</span>
          <time
            className="spec-cont"
            itemProp="datePublished"
            dateTime="2022-04-12T16:36"
          >
            12-04-2022, 16:36
          </time>
        </li>
        <li className="specs-item">
          <i className="spec-icon c-green">
            <RiShieldFlashFill size={24} />
          </i>
          <span className="spec-label">Version</span>
          <span className="spec-cont" itemProp="softwareVersion">
            2.7.8
          </span>
        </li>
        <li className="specs-item">
          <i className="spec-icon c-green">
            <FcAndroidOs size={24} />
          </i>
          <span className="spec-label">Requirements</span>
          <span className="spec-cont" itemProp="operatingSystem">
            Android 7.0
          </span>
        </li>
        <li className="specs-item">
          <i className="spec-icon c-green">
            <MdOutlineCategory size={24} />
          </i>
          <span className="spec-label">Genre</span>
          <span className="spec-cont" itemProp="applicationCategory">
            Action
          </span>
        </li>
        <li className="specs-item" itemProp="applicationCategory">
          <i className="spec-icon c-green">
            <GiPriceTag size={24} />
          </i>
          <span className="spec-label">Price</span>
          <span
            itemScope=""
            itemProp="offers"
            itemType="http://schema.org/Offer"
          >
            <meta itemProp="price" content="0" />
            <meta
              itemProp="priceCurrency"
              content="USD"
              className="spec-cont"
            />
            Free
          </span>
        </li>
      </ul>
    </div>
  );
}

function AppImage() {
  return (
    <div className="view-app-img">
      <figure className="img">
        <span className="view-img-label s-purple">ARM64</span>
        <img
          src="/uploads/posts/2.webp"
          alt="Dead Cells"
          width="240"
          height="240"
        />
      </figure>
      <div className="view-app-tools c-yellow">
        <a id="fav-id-13694" href="/">
          <BsBookmarkHeart size={24} />
          <span>To bookmarks</span>
        </a>
      </div>
    </div>
  );
}

function ScreenShortWidget() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    "https://5play.ru/uploads/posts/2022-04/1649330859_2.webp",
    "https://5play.ru/uploads/posts/2022-04/1649330859_2.webp",
    "https://5play.ru/uploads/posts/2022-04/1649330859_2.webp",
    "https://5play.ru/uploads/posts/2022-04/1649330859_2.webp",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="block b-screens">
      <div className="b-icon-title">
        <i className="s-yellow c-icon">
          <RiCameraLensFill size={24} />
        </i>
        <h3 className="b-title">Screenshots </h3>
      </div>
      <div className="b-cont">
        <div className="screenshots">
          {images.map((src, index) => (
            <span key={index}>
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                width="300"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            </span>
          ))}

          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ViewMinList() {
  return (
    <div className="wrp-min block-list">
      <BlockAddInfo />
      <div className="anchor-line">
        <span id="download-block"></span>
      </div>
      <DownloadWidget />
      <AlertLine />
      <CommentListWidget />
      <TakeComment />
    </div>
  );
}

function AlertLine() {
  return (
    <div className="alert-line">
      <div className="wrp-min">
        <i className="i__info">
          <BsFillInfoCircleFill size={24} />
        </i>
        <div className="alert-cont">
          Login or{" "}
          <a
            href="/index.php?do=register&amp;lang=en"
            target="/_blank/"
            rel="/nofollow/"
          >
            {" "}
            register
          </a>{" "}
          to post comments
        </div>
      </div>
    </div>
  );
}

function DownloadWidget() {
  return (
    <div className="block b-download">
      <div className="b-nobugs">
        <div className="b-nobugs-icon">
          <figure className="img">
            <img
              src="/uploads/posts/2.webp"
              alt="Dead Cells"
              width="44"
              height="44"
              loading="auto"
            />
          </figure>
          <i className="c-green">
            <RiShieldCheckFill size={24} />
          </i>
        </div>
        <div className="b-nobugs-text">
          <span>Download Dead Cells free for android</span>
        </div>
      </div>
      <div className="b-cont">
        <div className="downline-line-list">
          Mod - unlocked
          <br />
          <a href="/" className="download-line s-green" target="_blank">
            <div className="download-line-title">
              <i>
                <MdGetApp size={24} />
              </i>
              <span>dead-cells-2.7.8-mod-t-5play.ru.apk</span>
            </div>
            <span className="download-line-size">
              Download <span className="uppercase">apk</span> - 1.3 Gb
            </span>
          </a>
          <br />
          Mod - menu
          <br />
          <a href="/" className="download-line s-green" target="_blank">
            <div className="download-line-title">
              <i>
                <MdGetApp size={24} />
              </i>
              <span>dead-cells-2.7.8-mod-menu-5play.ru.apk</span>
            </div>
            <span className="download-line-size">
              Download <span className="uppercase">apk</span> - 1.3 Gb
            </span>
          </a>
        </div>
        <div className="spoiler">
          <div className="title_spoiler">
            <a href="/">
              <img
                id="image-sp_info"
                src="/dleimages/spoiler-minus.gif"
                width="24"
                height="24"
                alt=""
              />
              <span className="sr-only">Show/Hide</span>
            </a>
            Information
          </div>
          <div
            id="sp_info"
            className="text_spoiler"
            style={{
              display: "block",
            }}
          >
            Mod <b>Unlocked</b> info:
            <br />- license check is disabled
            <br />- all DLC purchased and installed
            <br />
            <br />
            Mod <b>menu</b> info:
            <br />- license check is disabled
            <br />- all DLC purchased and installed
            <br />
            <br />
            <span
              style={{
                color: "#418525",
              }}
            >
              <b>Options are available for menu control</b>
            </span>
            <br />
            <span
              style={{
                color: "#3984C6",
              }}
            >
              <i>
                <br />- god mode
                <br />- high damage
                <br />- unlimited money
                <br />- unlimited cells
              </i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function BlockAddInfo() {
  $(function () {
    var tabContainers = $(".tab-content .tab-pane");
    tabContainers.hide().filter(":first").show();

    $(".tab-item")
      .click(function () {
        tabContainers.hide();
        tabContainers.filter(this.hash).show();
        $(".tab-item").removeClass("active");
        $(this).addClass("active");
        return false;
      })
      .filter(":first")
      .click();
  });

  $(".needreg-btn").click(function () {
    $("#needreg").dialog({
      show: "fade",
      hide: "fade",
      width: 400,
      buttons: {
        Close: function () {
          $(this).dialog("close");
        },
      },
    });
  });
  return (
    <div className="block b-add-info">
      <div className="b-tabs" role="tablist">
        <a className="tab-item active" href="#app_description">
          Description
        </a>
        <a className="tab-item" href="#app_faq">
          Help
        </a>
      </div>
      <div className="b-cont tab-content">
        <div
          className="tab-pane text"
          id="app_description"
          style={{
            display: "block",
          }}
        >
          <b>Dead Cells</b> - award-winning and highest estimates of the
          two-dimensional action game with elements of roguelike. The player can
          immerse themselves in an exciting adventure through the many levels
          together with the main character. During the passage of the characters
          to find ways to move forward, and search goals can be accomplished in
          several ways. Also he will have to face a lot of enemies to deal with
          which will help more than fifty types of weapons and powerful magic
          spells. Additionally, the protagonist expect hidden rooms and
          locations, concealing a lot of interesting things.
        </div>
        <div
          className="tab-pane"
          id="app_faq"
          style={{
            display: "none",
          }}
        >
          <a className="link-faq" href="/en/download-help.html">
            Can't download?
          </a>
          <a
            className="link-faq"
            href="/en/ustanovka-igr-i-programm-na-android.html"
          >
            Installing games and programs
          </a>
          <a
            className="link-faq"
            href="/en/ustanovka-igr-s-keshem-na-android.html"
          >
            Installing games with a cache
          </a>
          <a
            className="link-faq"
            href="/en/kak-sdelat-skrinshot-na-android.html"
          >
            How to make a screenshot
          </a>
        </div>
      </div>
    </div>
  );
}

function SpeedBar() {
  return (
    <div className="wrp-min speedbar">
      <div className="speedbar-panel">
        <a href="https://5play.ru/en/">5play.ru</a> /{" "}
        <a href="https://5play.ru/en/android/">Android</a> /{" "}
        <a href="https://5play.ru/en/android/games/">Games</a> /{" "}
        <a href="https://5play.ru/en/android/games/action/">Action</a> / Dead
        Cells
      </div>
    </div>
  );
}

export default ItemView;
