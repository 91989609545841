import React from "react";
import { MyCFooter } from "./footer";
import ItemWidget from "../widget/itemWidget";
import BackgroundOne from "../widget/backroundOne";
class FavoriteItems extends React.Component {
  render() {
    return (
      <>
        <FavoreiteHead />
        <PageCatBg />
      </>
    );
  }
}

function FavoreiteHead() {
  return (
    <div class="page-head-cat">
      <div class="wrp-min">
        <div class="head-cat-title">
          <h1 class="title">In bookmarks</h1>
        </div>
      </div>
      <i class="bg-clouds"></i>
    </div>
  );
}

function Items() {
  return (
    <div className="wrp page-cat-cont">
      <div className="entry-listpage list-c6">
        <ItemsList />
      </div>
    </div>
  );
}

function ItemsList() {
  const listItems = [];

  for (let i = 0; i < 10; i++) {
    listItems.push(
      <ItemWidget
        key={i}
        data={{
          key: i,
          title: "Combat Quest - Archer Action RPG",
          description: "description",
          new: true,
          update: false,
          image: "/uploads/posts/2.webp",
          link: "/",
          android: "5.0",
          version: "1.0",
          categorie: "Action",
          status: "MOD",
        }}
      />
    );
  }
  return listItems;
}

function PageCatBg() {
  return (
    <div className="page-cat-bg">
      <Items />
      <MyCFooter />
      <BackgroundOne />
    </div>
  );
}

export default FavoriteItems;
