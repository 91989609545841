import React from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import EditUserModal from "../widget/editUserModal";
import { userModalToogle } from "../stores/counterSlice";
import { MyCFooter } from "./footer";
class ProfileView extends React.Component {
  render() {
    return (
      <>
        <PageUserView />
        <PageCatBg />
      </>
    );
  }
}

function PageUserView() {
  const dispatch = useDispatch();
  const toogle = () => {
    dispatch(userModalToogle());
  };
  return (
    <div class="page-sys page-user">
      <section class="section">
        <form
          method="post"
          name="userinfo"
          id="userinfo"
          enctype="multipart/form-data"
          action="https://5play.ru/en/user/yilan53/"
        >
          <div class="wrp-min block-list">
            <div class="block">
              <div class="userpage_head online">
                <div class="userpage-main-info">
                  <div class="userpage-status">Visitors</div>
                  <div class="userpage-main-circle">
                    <i class="avatar fit-cover">
                      <img src="/dleimages/noavatar.webp" alt="yilan53" />
                    </i>
                    <button
                      class="user_edit btn c-icon s-green"
                      type="button"
                      onClick={toogle}
                      data-toggle="modal"
                      data-target="#userset"
                    >
                      <MdEdit size={24} />
                    </button>
                    <div class="user_status">
                      <span class="c-muted uppercase small">Online</span>
                    </div>
                    <svg
                      version="1.1"
                      viewBox="0 0 640 640"
                      width="640"
                      height="640"
                    >
                      <circle class="st0" cx="320" cy="320" r="178"></circle>
                      <circle class="st1" cx="320" cy="320" r="319"></circle>
                    </svg>
                  </div>
                  <div class="userpage-main-names">
                    <h1 class="title">yilan53</h1>
                    <div class="fullname">АНГЛИЙСКИЙ</div>
                  </div>
                </div>
              </div>
              <div class="b-cont">
                <ul class="userpage-addon">
                  <li>
                    <div class="item">
                      <span class="uppercase muted small">Registered</span>
                      <div class="fw-b">16 July 2022 10:35</div>
                    </div>
                  </li>
                  <li>
                    <div class="item">
                      <span class="uppercase muted small">Last Activity</span>
                      <div class="fw-b">16 July 2022 14:00</div>
                    </div>
                  </li>
                  <li>
                    <div class="item">
                      <span class="uppercase muted small">Published by</span>
                      <div class="fw-b">0</div>
                    </div>
                  </li>
                  <li>
                    <div class="item">
                      <span class="uppercase muted small">Comments</span>
                      <div class="fw-b">0</div>
                    </div>
                  </li>
                </ul>
                <div class="userpage-addon-foot">
                  <a
                    href="/index.php?do=pm&amp;doaction=newpm&amp;username=yilan53&amp;lang=en"
                    rel="nofollow"
                    class="btn btn-block s-green"
                  >
                    Send message
                  </a>
                </div>
              </div>
            </div>
          </div>
          <EditUserModal />
          <input type="hidden" name="doaction" value="adduserinfo" />
          <input type="hidden" name="id" value="101517" />
          <input
            type="hidden"
            name="dle_allow_hash"
            value="9a0f733d77462e02a924d53d6474bc8449e8fde8"
          />
        </form>
      </section>
    </div>
  );
}

function PageCatBg() {
  return (
    <>
      <MyCFooter />
      <div className="background bg-style-1">
        <i className="bg-circle-purple"></i>
        <i className="bg-circle-yellow bgc-1"></i>
        <i className="bg-circle-yellow bgc-2"></i>
        <i className="bg-circle-green"></i>
        <i className="bg-clouds">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2808 800"
            width="2808"
            height="800"
          >
            <path
              className="cloud-c1"
              d="M2066,300h-2a50,50,0,0,0,0,100h2a50,50,0,0,0,0-100Zm459.27-100H2758a50,50,0,0,0,0-100H2577.58a50,50,0,0,1,0-100H2126.45a50,50,0,0,1-.06,100H1970a50,50,0,0,0,0,100h232.45a50,50,0,0,1-.08,100H2186a50,50,0,0,0,0,100h104.46a50,50,0,0,1,0,100H2282a50,50,0,0,0,0,100h428a50,50,0,0,0,0-100H2601.55a50,50,0,0,1-.3-100H2606a50,50,0,0,0,0-100h-80.45a50,50,0,1,1-.28-100Z"
            ></path>
            <path
              className="cloud-c2"
              d="M2142,324H1969.55a50,50,0,0,1,0-100H1998a50,50,0,0,0,0-100H1770a50,50,0,0,0,0,100h14.26a50,50,0,1,1,0,100H1718a50,50,0,0,0,0,100h424a50,50,0,0,0,0-100Zm132-200H2170a50,50,0,0,0,0,100h104a50,50,0,0,0,0-100Z"
            ></path>
            <path
              className="cloud-c1"
              d="M962,100H781.58a50,50,0,0,1,0-100H206.45a50,50,0,0,1-.06,100H50a50,50,0,0,0,0,100H282.45a50,50,0,0,1-.08,100H266a50,50,0,0,0,0,100H370.46a50,50,0,1,1,0,100H362a50,50,0,0,0,0,100h8.46a50,50,0,1,1,0,100h-24a50,50,0,0,0,0,100H590a50,50,0,0,0,0-100H509.55a50,50,0,1,1,.08-100H790a50,50,0,0,0,0-100H681.55a50,50,0,0,1-.3-100H686a50,50,0,0,0,0-100H605.55a50,50,0,1,1-.28-100H962a50,50,0,0,0,0-100Zm168,0h-28a50,50,0,0,0,0,100h28a50,50,0,0,0,0-100Z"
            ></path>
            <path
              className="cloud-c2"
              d="M1086,244H913.57a50,50,0,0,1,0-100H962a50,50,0,0,0,0-100H654a50,50,0,0,0,0,100h28a50,50,0,0,1,0,100H642a50,50,0,0,0,0,100h444a50,50,0,0,0,0-100Z"
            ></path>
          </svg>
        </i>
      </div>
    </>
  );
}

export default ProfileView;
