import React from "react";
import Modal from "react-bootstrap/Modal";
import { userModalToogle } from "../stores/counterSlice";
import { useDispatch, useSelector } from "react-redux";

class EditUserModal extends React.Component {
  render() {
    return <UserEdit />;
  }
}
function UserEdit() {
  const dispatch = useDispatch();
  const { showModalUserEdit } = useSelector((state) => state.counter);
  console.log(showModalUserEdit);
  const toogle = () => {
    dispatch(userModalToogle());
  };
  return (
    <>
      <Modal show={showModalUserEdit} onHide={toogle}>
        <Modal.Header closeButton>
          <Modal.Title>Settings account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body" id="options">
            <div class="form-group">
              <label class="c-muted" for="fullname">
                Login
              </label>
              <input
                class="form-control"
                type="text"
                maxlength="50"
                name="fullname"
                id="fullname"
                value="АНГЛИЙСКИЙ"
              />
            </div>
            <div class="form-group">
              <label class="c-muted" for="email">
                E-Mail
              </label>
              <input
                class="form-control"
                type="email"
                maxlength="50"
                name="email"
                id="email"
                value="info@basusta.net"
                required=""
              />
            </div>
            <hr class="my-4" />
            <div class="form-group">
              <label class="c-muted" for="altpass">
                Old Password
              </label>
              <input
                class="form-control"
                type="password"
                name="altpass"
                id="altpass"
              />
            </div>
            <div class="form-group">
              <label class="c-muted" for="password1">
                New Password
              </label>
              <input
                class="form-control"
                type="password"
                name="password1"
                id="password1"
              />
            </div>
            <div class="form-group">
              <label class="c-muted" for="password2">
                Repeat new password
              </label>
              <input
                class="form-control"
                type="password"
                name="password2"
                id="password2"
              />
            </div>
            <hr class="my-4" />
            <div class="form-group">
              <label class="c-muted" for="image">
                Upload your avatar
              </label>
              <input type="file" id="image" name="image" class="form-control" />
            </div>
            <div class="form-group">
              <label class="c-muted" for="image">
                Used Gravatar
              </label>
              <input
                type="text"
                name="gravatar"
                id="gravatar"
                value=""
                class="form-control"
              />
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                name="del_foto"
                class="custom-control-input"
                value="yes"
                id="del_foto"
              />
              <label class="custom-control-label" for="del_foto">
                Delete avatar
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-block s-green" type="submit" name="submit">
            Save
          </button>
          <button
            type="button"
            class="btn btn-block s-yellow"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUserModal;
