import MyHeader from "./header";
import { MyRouter } from "./router";
import ModalLogin from "./widget/login_dialog";
function App() {
  return (
    <>
      <MyHeader />
      <MyRouter />
      <ModalLogin />
    </>
  );
}

export default App;
