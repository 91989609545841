import React from "react";
import Modal from "react-bootstrap/Modal";
import { modalToogle } from "../stores/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RiFacebookFill } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";

class ModalLogin extends React.Component {
  render() {
    return <LoginDialog />;
  }
}
function LoginDialog() {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.counter);
  console.log(showModal);
  const toogle = () => {
    dispatch(modalToogle());
  };
  return (
    <>
      <Modal show={showModal} onHide={toogle}>
        <Modal.Header closeButton>
          <Modal.Title>Login to 5play.ru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-links">
            <a
              className="soc-fb"
              href="/"
              target="_blank"
              rel="nofollow noopener"
            >
              <RiFacebookFill
                style={{
                  width: "32",
                  height: "32",
                  color: "white",
                  padding: "5",
                }}
              />
            </a>
            <a
              className="soc-gl"
              href="/"
              target="_blank"
              rel="nofollow noopener"
            >
              <FcGoogle
                style={{
                  width: "32",
                  height: "32",
                  color: "white",
                  padding: "5",
                }}
              />
            </a>
          </div>
          <form className="login_form" method="post">
            <label className="form-group">
              <span className="c-muted">Login</span>
              <input
                className="form-control"
                type="text"
                name="login_name"
                required=""
              />
            </label>
            <label className="form-group">
              <a className="f-right" href="/forgot-password">
                Forgot your password?
              </a>
              <span className="c-muted">Password</span>
              <input
                className="form-control"
                type="password"
                name="login_password"
                required=""
              />
            </label>
            <input name="login" type="hidden" id="login" value="submit" />
            <div className="form-submit btn-group">
              <button className="btn btn-block s-green" type="submit">
                Login
              </button>
              <a
                href="https://5play.ru/index.php?do=register&amp;lang=en"
                className="btn btn-block s-yellow"
              >
                Registration
              </a>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalLogin;
