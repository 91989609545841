import React from "react";
//import { useScreenSize } from "./utils/screen_size";
import { IoMdStats } from "react-icons/io";
import { RiShieldFlashFill } from "react-icons/ri";
import { FaRegComments, FaReply } from "react-icons/fa";
import { MyCFooter } from "./pages/footer";

class MyFooter extends React.Component {
  render() {
    return (
      <div className="dark-foot dark-section">
        <SelectionNews />
        <LastComments />
        <MyCFooter />
        <div
          className="background"
          style={{
            display: "none",
          }}
        >
          <i className="bg-circle-green"></i>
          <i className="bg-clouds">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2550 700"
              width="2550"
              height="700"
            >
              <path
                fill="#142636"
                d="M554,600h-4a50,50,0,0,0,0,100h4a50,50,0,0,0,0-100ZM802,0H50a50,50,0,0,0,0,100h78.08a50,50,0,0,1,.11,100H98a50,50,0,0,0,0,100H238a50,50,0,1,1,0,100H194a50,50,0,0,0,0,100h56a50,50,0,0,1,.3,100H238a50,50,0,0,0,0,100H430a50,50,0,0,0,0-100H413.55a50,50,0,0,1,.05-100H630a50,50,0,0,0,0-100H521.55a50,50,0,0,1,0-100H526a50,50,0,0,0,0-100H445.55a50,50,0,0,1,.08-100H802A50,50,0,0,0,802,0Z"
              ></path>
              <path
                fill="#142636"
                d="M2073,500a50,50,0,0,1,0-100h16.43a50,50,0,0,0,0-100H1901a50,50,0,0,1,0-100h216.42a49.92,49.92,0,0,0,34.94-14.64,50,50,0,0,0-34.9-85.36H2097a50,50,0,0,1-50-50h0a50,50,0,0,1,50-50h252a50,50,0,0,1,0,100h-28a50,50,0,1,0,0,100h24a50,50,0,0,1,0,100h-24a50,50,0,1,0,0,100h179a50,50,0,0,1,0,100Z"
              ></path>
            </svg>
          </i>
        </div>
      </div>
    );
  }
}

function LastComments() {
  return (
    <section className="wrp section section-comments">
      <h3 className="section-title">
        <i className="s-green c-icon">
          <FaRegComments
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </i>
        Latest comments
      </h3>
      <div className="scroll-entry-list">
        <div className="entry-list list-c3">
          <div className="entry entry-coms">
            <div className="item">
              <a
                href="https://5play.ru/en/15429-real-driving-school.html#comment"
                className="user"
              >
                <i className="avatar fit-cover">
                  <img src="dleimages/noavatar.webp" alt="Lat" loading="lazy" />
                </i>
                <span className="fw-b truncate">Lat</span>
              </a>
              <h2 className="title">
                <a
                  className="item-link"
                  href="https://5play.ru/en/15429-real-driving-school.html#comment"
                >
                  <span className="truncate">Real Driving School</span>
                </a>
              </h2>
              <div className="description">
                Hi 5play.ru modder please fix the muiltiplayer it says I'm
                banned if I wanna play muiltiplayer pls and thx
              </div>
              <time className="date muted" dateTime="2022-07-13T23:13">
                Yesterday, 23:13
              </time>
              <i className="entry-coms-reply">
                <FaReply
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </i>
            </div>
          </div>
          <div className="entry entry-coms">
            <div className="item">
              <a
                href="https://5play.ru/en/13940-family-island-farm-game-adventure.html#comment"
                className="user"
              >
                <i className="avatar fit-cover">
                  <img
                    src="dleimages/noavatar.webp"
                    alt="GoldenFish"
                    loading="lazy"
                  />
                </i>
                <span className="fw-b truncate">GoldenFish</span>
              </a>
              <h2 className="title">
                <a
                  className="item-link"
                  href="https://5play.ru/en/13940-family-island-farm-game-adventure.html#comment"
                >
                  <span className="truncate">
                    Family Island™ - Farm game adventure
                  </span>
                </a>
              </h2>
              <div className="description">
                How to use this Mod, nothing happened.
              </div>
              <time className="date muted" dateTime="2022-07-13T21:43">
                Yesterday, 21:43
              </time>
              <i className="entry-coms-reply">
                <FaReply
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </i>
            </div>
          </div>
          <div className="entry entry-coms">
            <div className="item">
              <a
                href="https://5play.ru/en/10799-zooba-free-for-all-adventure-battle-game.html#comment"
                className="user"
              >
                <i className="avatar fit-cover">
                  <img
                    src="//5play.ru/uploads/fotos/foto_46710.jpg"
                    alt="Josemanriquez"
                    loading="lazy"
                  />
                </i>
                <span className="fw-b truncate">Josemanriquez</span>
              </a>
              <h2 className="title">
                <a
                  className="item-link"
                  href="https://5play.ru/en/10799-zooba-free-for-all-adventure-battle-game.html#comment"
                >
                  <span className="truncate">
                    Zooba: Free-for-all - Adventure Battle Game
                  </span>
                </a>
              </h2>
              <div className="description">
                Que pasa con la version 3.32??? Actualizar por favor
              </div>
              <time className="date muted" dateTime="2022-07-13T20:45">
                Yesterday, 20:45
              </time>
              <i className="entry-coms-reply">
                <FaReply
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function SelectionNews() {
  return (
    <section className="wrp section section-news">
      <div className="section-head">
        <h3 className="section-title">
          <i className="s-blue c-icon">
            <RiShieldFlashFill
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </i>
          Last news
        </h3>
        <a className="btn s-green btn-all" href="/en/android/news/">
          <span>All news</span>
        </a>
      </div>
      <div className="entry-list list-c2">
        <div className="entry entry-news">
          <div className="item">
            <div className="pic">
              <figure className="fit-cover">
                <img
                  src="/uploads/posts/1.webp"
                  alt="Android 13 branch beta released"
                  width="300"
                  height="300"
                  loading="lazy"
                />
              </figure>
            </div>
            <div className="cont">
              <div className="meta muted">
                <time className="meta-date" dateTime="2022-07-14T12:40">
                  Today, 12:40
                </time>
                <div className="meta-view">
                  <IoMdStats
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  62
                </div>
              </div>
              <h2 className="title">
                <a
                  className="item-link"
                  href="https://5play.ru/en/16849-android-13-branch-beta-released.html"
                >
                  <span>Android 13 branch beta released</span>
                </a>
              </h2>
              <div
                className="description"
                style={{
                  display: "none",
                }}
              >
                Google presented the fourth and final version of the test
                version of its new operating system. It is available for
                download for owners of Pixel 4/4XL and above.
              </div>
              <div className="read-more">
                <a href="https://5play.ru/en/16849-android-13-branch-beta-released.html">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="entry entry-news">
          <div className="item">
            <div className="pic">
              <figure className="fit-cover">
                <img
                  src="/uploads/posts/1.webp"
                  alt="Xiaomi has released a laser projector for games and watching movies"
                  width="300"
                  height="300"
                  loading="lazy"
                />
              </figure>
            </div>
            <div className="cont">
              <div className="meta muted">
                <time className="meta-date" dateTime="2022-07-14T12:35">
                  Today, 12:35
                </time>
                <div className="meta-view">
                  <IoMdStats
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  40
                </div>
              </div>
              <h2 className="title">
                <a
                  className="item-link"
                  href="https://5play.ru/en/16848-xiaomi-has-released-a-laser-projector-for-games-and-watching-movies.html"
                >
                  <span>
                    Xiaomi has released a laser projector for games and watching
                    movies
                  </span>
                </a>
              </h2>
              <div
                className="description"
                style={{
                  display: "none",
                }}
              >
                The updated 1S model from 2022 has increased brightness and a
                special gaming mode.
              </div>
              <div className="read-more">
                <a href="https://5play.ru/en/16848-xiaomi-has-released-a-laser-projector-for-games-and-watching-movies.html">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyFooter;
