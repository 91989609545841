import React from "react";
import BackgroundOne from "../widget/backroundOne";

import { MyCFooter } from "./footer";
class DmcaPage extends React.Component {
  render() {
    return (
      <>
        <PageCatCont />
        <MyCFooter />
        <BackgroundOne />
      </>
    );
  }
}

function PageCatCont() {
  return (
    <div className="page-sys">
      <section className="section">
        <div className="wrp-min block-list">
          <div className="block static-page">
            <div className="b-cont">
              <h1 className="title">DMCA</h1>
              <div className="text">
                <p>
                  <b>Denial of responsibility</b>
                </p>
                <p>
                  1. The information placed on the pages 5play.ru (hereinafter
                  referred to as the Site) is intended for free familiarization
                  of users with questions that may be of interest to them.
                </p>
                <p>
                  2. All information is provided in its original form, without
                  guarantees of completeness or timeliness, and without other
                  express or implied warranties. Access to the Site, as well as
                  the use of its Content, is carried out exclusively at your
                  discretion and at your own risk.
                </p>
                <p>
                  3. Administration (Site owners, officials, directors,
                  shareholders, founders, employees, agents, administrators,
                  moderators, moderators, moderator assistants, routers,
                  curators and other representatives) makes every effort to
                  provide users with accurate and reliable information, but at
                  the same time does not exclude the possibility of errors.
                </p>
                <p>
                  4. Site 5play.ru is a project with open content. Its structure
                  allows anyone who has access to the Internet to change its
                  content, therefore the Site and its Content are freely
                  provided as an act of goodwill, on an "as is" basis, without
                  entering into any agreements or agreements between you, the
                  users of this Site, The Administration, the owners of the
                  servers on which it is hosted, or by someone else, in any way
                  connected with this or related projects, which [the contracts]
                  may become the subject of direct claims.
                </p>
                <p>
                  5. The Administration does not give any assurances or
                  guarantees regarding the Site and its Content, including
                  without limitation, timeliness, relevance, accuracy,
                  completeness, reliability, accessibility or compliance for any
                  particular purpose of the Site and the Content, with respect
                  to the fact that when using the Site, there will be no errors,
                  it will be safe and uninterrupted, that the Administration
                  will correct any errors, or that the Site will not contain
                  viruses or other malicious codes, and that the Content and the
                  Site do not violate rights of third parties.
                </p>
                <p>
                  6. Some links on this Site lead to resources located on
                  third-party sites. These links are placed for the convenience
                  of users and do not mean that the Administration approves the
                  content of other sites. In addition, the Site Administration
                  assumes no responsibility for the availability of these
                  resources and for their content. This statement applies to all
                  links provided on the Site, and to the materials of all
                  websites accessible through banners and links on the website
                  at 5play.ru.
                </p>
                <p>
                  7. It is the responsibility of the Administration not to
                  control the legality or illegality of the information
                  transmitted (any, including but not limited to information
                  transmitted between users, internal transfer of information in
                  the form of various links, texts or archives), determining the
                  ownership or legitimacy of the transfer, reception or use this
                  information.
                </p>
                <p>
                  8. The Administration takes reasonable steps to ensure the
                  accuracy, relevance and legitimacy of the Content, but it does
                  not assume responsibility for the actions of persons or
                  organizations, directly or otherwise carried out on the basis
                  of information available on the Site or received through it,
                  as provided by the Administration, so and third parties.
                </p>
                <p>
                  9. In accordance with current legislation, the Administration
                  disclaims any representations or warranties that may be
                  otherwise implied and disclaims liability with respect to the
                  Site, the Content and its use. Under no circumstances will the
                  Site Administration be liable to any party for any direct,
                  indirect, special or other indirect damage as a result of any
                  use of information on this Site or on any other website to
                  which there is a hyperlink from our Site, the occurrence of
                  dependence, decrease in productivity, dismissal or
                  interruption of labor activity, as well as deductions from
                  educational institutions, for any lost profits, suspension of
                  economic activities, loss of programs or data in Your
                  information systems or otherwise, arising from the access, use
                  or inability to use the Site, the Content or any linked
                  Internet site, or the inability, error, omission,
                  interruption, defect, idle time or delay in transmission,
                  computer virus or a system failure, even if the administration
                  is explicitly informed of the possibility of such damage.
                </p>
                <p>
                  10. For advertising placed on the Site, only the advertiser is
                  liable. The site specifically notifies that it does not
                  guarantee the possibility of purchasing or using certain goods
                  or services at prices and / or on terms specified in the
                  advertising blocks (texts, banners). You agree that the Site
                  is not responsible for any consequences (including any damage)
                  arising from any relationship with advertisers.
                </p>
                <p>
                  11. The Site provides for the possibility to send users
                  questions, comments, suggestions and other information with a
                  view to including it in accordance with
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DmcaPage;
