import { FaTelegramPlane } from "react-icons/fa";
import { SiAndroidstudio } from "react-icons/si";
import { BiChevronsUp } from "react-icons/bi";
export const MyCFooter = () => {
  return (
    <footer className="footer">
      <div className="wrp-min">
        <div className="footer-panel">
          <a
            className="logotype scrollup"
            style={{
              display: "none",
            }}
            href="/en/"
            title="5play"
          >
            <span className="sr-only">5play</span>
            <i className="logo-icon">
              <SiAndroidstudio
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </i>
          </a>
          <a className="upper scrollup" href="/en/" title="Наверх">
            <span className="sr-only">Scroll up</span>
            <BiChevronsUp
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </a>
          <div className="footer-cont">
            <div className="copyright">
              2016-2022 © 5play.ru - Games and apps for android
            </div>
            <div className="f-telegram">
              <a
                href="/"
                target={"_blank"}
                rel="noopener noreferrer"
                alt="Telegram"
              >
                <i className="c-icon s-telegram">
                  <FaTelegramPlane
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </i>
                <span>Our Telegram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
