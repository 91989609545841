import React from "react";
import "../form.css";
class PrivateMessagePage extends React.Component {
  componentDidMount() {
    const headerHide = document.getElementById("myheader");
    headerHide.style.display = "none";
  }

  render() {
    return (
      <>
        <MessageBox />
      </>
    );
  }
}

function MessageBox() {
  return (
    <section className="page-form">
      <div className="page-form-left">
        <div className="page-form-head">
          <a className="logotype" href="/en/" title="5play">
            <span className="sr-only">5play</span>
            5play
          </a>
          <h1 className="title">Games and apps for Android</h1>
        </div>
        <i className="page-form-bg"></i>
      </div>
      <div class="page-form-right">
        <div class="page-form-right-in">
          <div class="pag-form_head">
            <a class="back_to_main" href="/en/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="24"
                width="24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H6.83l2.88-2.88c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L3.71 11.3c-.39.39-.39 1.02 0 1.41L8.3 17.3c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                ></path>
              </svg>
              Back to the main page
            </a>
          </div>
          <main class="page-form-cont">
            <div class="wrp-form-mid">
              <form
                method="post"
                name="dle-comments-form"
                id="dle-comments-form"
                action=""
              >
                <h2 class="heading">Private messages</h2>
                <div class="pm-box">
                  <div class="pm-status">
                    <span class="pm-status-info">0 % / (20 messages)</span>
                    <div
                      class="pm_progress_bar"
                      title="Size of personal message folders is: 0%"
                    >
                      <span
                        style={{
                          width: "0%",
                        }}
                      >
                        0%
                      </span>
                    </div>
                  </div>
                  <ul class="pm-menu">
                    <li>
                      <a href="/index.php?do=pm&amp;folder=inbox&amp;lang=en">
                        <svg
                          style={{
                            display: "none",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          height="24"
                          width="24"
                        >
                          <path
                            fill="currentColor"
                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-6.54 4.09c-.65.41-1.47.41-2.12 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z"
                          ></path>
                        </svg>
                        <span>Inbox</span>
                      </a>
                    </li>
                    <li>
                      <a href="/index.php?do=pm&amp;folder=outbox&amp;lang=en">
                        <svg
                          style={{
                            display: "none",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          height="24"
                          width="24"
                        >
                          <path
                            fill="currentColor"
                            d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h9v-2H4V8l6.94,4.34c0.65,0.41,1.47,0.41,2.12,0L20,8v5h2V6C22,4.9,21.1,4,20,4 z M12,11L4,6h16L12,11z M19,16.21c0-0.45,0.54-0.67,0.85-0.35l2.79,2.79c0.2,0.2,0.2,0.51,0,0.71l-2.79,2.79 C19.54,22.46,19,22.24,19,21.79V20h-3c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h3V16.21z"
                          ></path>
                        </svg>
                        <span>Outbox</span>
                      </a>
                    </li>
                    <li>
                      <a href="/index.php?do=pm&amp;doaction=newpm&amp;lang=en">
                        <svg
                          style={{
                            display: "none",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          height="24"
                          width="24"
                        >
                          <path
                            fill="currentColor"
                            d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z"
                          ></path>
                        </svg>
                        <span>Write a message</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <span id="dle-pm-preview"></span>

                <h3 class="title">New message</h3>
                <div class="form-group">
                  <label class="text-muted" for="name">
                    User login
                  </label>
                  <input
                    maxlength="50"
                    class="form-control"
                    type="text"
                    name="name"
                    id="name"
                    value=""
                    required=""
                  />
                </div>
                <div class="form-group">
                  <label class="text-muted" for="subj">
                    Subject
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    name="subj"
                    id="subj"
                    value=""
                    required=""
                  />
                </div>
                <div class="form-group">
                  <label class="text-muted" for="message">
                    Message
                  </label>
                  <textarea
                    class="form-control"
                    id="message"
                    name="comments"
                    rows="8"
                  ></textarea>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="outboxcopy"
                      class="custom-control-input"
                      value="1"
                      id="outboxcopy"
                    />
                    <label class="custom-control-label" for="outboxcopy">
                      Save in outbox
                    </label>
                  </div>
                </div>
                <div class="form-submit">
                  <button
                    class="btn s-green btn-block"
                    type="submit"
                    name="add"
                  >
                    Send message
                  </button>
                </div>
                <script
                  src="https://www.google.com/recaptcha/api.js?render=6Lf8P6oUAAAAAMERcNYVii_9PbbwWniPMPf6yqYt"
                  async=""
                  defer=""
                ></script>
                <input name="send" type="hidden" value="send" />
                <input
                  type="hidden"
                  name="user_hash"
                  value="9a0f733d77462e02a924d53d6474bc8449e8fde8"
                />
                <input
                  type="hidden"
                  name="duplicateprotection"
                  value="a3370fac4fd34c603ca451816eb4504a"
                />
              </form>
              <div></div>
            </div>
          </main>
          <div class="page-form_foot c-muted">
            <div class="copyright">
              2021 © 5play.ru - Games and apps for android
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivateMessagePage;
