import React from "react";
import "../cdn.css";
import { FaArrowLeft } from "react-icons/fa";
import { FcAndroidOs } from "react-icons/fc";
import { RiShieldFlashFill, RiTelegramFill } from "react-icons/ri";
import $ from "jquery";
class DownloadPage extends React.Component {
  componentDidMount() {
    const headerHide = document.getElementById("myheader");
    headerHide.style.display = "none";
    const toolbar = document.getElementById("toolbar");
    toolbar.style.display = "none";
    $("html").addClass("darktheme");
  }

  render() {
    return (
      <>
        <PageCdn />
        <Backround />
      </>
    );
  }
}

function PageCdn() {
  return (
    <div className="page-cdn">
      <div class="page-cdn-back">
        <a href="/en/">
          <i class="s-green c-icon">
            <FaArrowLeft size={24} />
          </i>
          Back to main
        </a>
      </div>
      <PageCdnContent />
    </div>
  );
}

function PageCdnContent() {
  return (
    <div class="page-cdn-cont">
      <header class="page-cdn-head">
        <a class="logotype" href="/en/" title="5play">
          <span class="sr-only">5play</span>
          5play
        </a>
      </header>
      <div
        style={{
          marginBottom: "3rem",
        }}
      >
        <ins
          class="adsbygoogle"
          style={{
            display: "block",
          }}
          data-ad-client="ca-pub-8970536578750070"
          data-ad-slot="5218430775"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script
          type="text/javascript"
          async=""
          src="https://www.google-analytics.com/analytics.js"
        ></script>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </div>
      <div class="download-cdn">
        <ul class="download-cdn-main">
          <li class="cdn-meta">
            <FcAndroidOs
              style={{
                width: "24px",
                height: "24px",
              }}
            />
            <span class="ww-break-word">5.0</span>
          </li>
          <li class="cdn-img">
            <i class="img">
              <img
                src="/uploads/posts/2.webp"
                alt="Combat Quest - Archer Action RPG"
                width="208"
                height="208"
              />
            </i>
          </li>
          <li class="cdn-meta">
            <RiShieldFlashFill
              style={{
                width: "24px",
                height: "24px",
              }}
            />
            <span class="ww-break-word">0.30.5</span>
          </li>
        </ul>
        <h1 class="title ww-break-word">
          Download combat-quest-archer-action-rpg-0.30.5-mod-t-5play.ru.apk
        </h1>
        <div class="md5">SHA-1: 9544d5301a1604e00699b65b143091d3</div>
        <div class="telegram-cdn-btn">
          <a
            class="btn btn-icon s-blue btn-lg btn-block"
            href="https://t.me/android_5play"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiTelegramFill
              style={{
                width: "24px",
                height: "24px",
              }}
            />
            <span>Our telegram</span>
          </a>
        </div>
        <div
          class="download-btn-group"
          style={{
            display: "inline-flex",
          }}
        >
          <div class="download-btn">
            <a href="https://s1.5playdisk.ru/42d6d333c23500373ca0bc936ea24f51:2022071617/files/combat-quest-archer-action-rpg-0.30.5-mod-t-5play.ru.apk">
              <input
                type="button"
                value="Download (144.12 Mb)"
                class="btn s-green btn-lg btn-block"
                onclick="this.disabled=1;  this.value='Loading...'; this.disabled=true;"
                disabled=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Backround() {
  return (
    <div class="background">
      <i class="bg-circle-green"></i>
      <i
        class="bg-clouds"
        style={{
          display: "none",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2550 700"
          width="2550"
          height="700"
        >
          <path
            fill="#142636"
            d="M554,100h-4A50,50,0,0,1,550,0h4a50,50,0,0,1,0,100Zm25,600H50a50,50,0,0,1,0-100h78.08a50,50,0,1,0,.11-100H98a50,50,0,0,1,0-100H238a50,50,0,0,0,0-100H194a50,50,0,0,1,0-100h56a50,50,0,0,0,.3-100H238A50,50,0,0,1,238,0H430a50,50,0,0,1,0,100H413.55a50,50,0,1,0,.05,100H630a50,50,0,0,1,0,100H521.55a50,50,0,1,0,0,100H526a50,50,0,0,1,0,100H445.55a50,50,0,0,0,.08,100H579a50,50,0,0,1,0,100Z"
          ></path>
          <path
            fill="#142636"
            d="M2073,700a50,50,0,0,1,0-100h16.43a50,50,0,0,0,0-100H1901a50,50,0,0,1,0-100h216.42a50,50,0,0,0,0-100H2097a50,50,0,0,1-50-50h0a50,50,0,0,1,50-50h252a50,50,0,0,1,0,100h-28a50,50,0,0,0,0,100h24a50,50,0,0,1,0,100h-24a50,50,0,0,0,0,100h179a50,50,0,0,1,0,100Z"
          ></path>
        </svg>
      </i>
    </div>
  );
}

export default DownloadPage;
