import React from "react";
import { MdApps, MdClose, MdFlashOn } from "react-icons/md";
import { GiGamepad, GiTrophyCup } from "react-icons/gi";
import { FcInvite, FcSearch } from "react-icons/fc";
import { FaUser } from "react-icons/fa";
import { BsFillMoonFill, BsSun } from "react-icons/bs";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { modalToogle } from "./stores/counterSlice";

class MyHeader extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("toggled-ttl") > Date.now()) {
      $("html").addClass("darktheme");
    } else {
      $("html").removeClass("darktheme");
    }

    $("#toggle-darkmod")
      .unbind("click")
      .bind("click", function () {
        console.log($("html").hasClass("darktheme"));
        if ($("html").hasClass("darktheme")) {
          localStorage.removeItem("toggled");
          localStorage.removeItem("toggled-ttl");
          $("html").removeClass("darktheme");
        } else {
          localStorage.setItem("toggled", 1);
          localStorage.setItem("toggled-ttl", Date.now() + 60 * 86400000);
          $("html").addClass("darktheme");
        }
      });

    $(document).ready(function () {
      $("html").removeClass("load");
    });

    $(function () {
      $(".dropdown-form").click(function (e) {
        e.stopPropagation();
      });

      $(".q-search-call")
        .unbind("click")
        .bind("click", function () {
          $(".q-search").toggleClass("open");
          setTimeout(function () {
            $(".header").toggleClass("qs");
          }, 50);
          return false;
        });

      $(".scrollup").click(function () {
        $("html, body").animate({ scrollTop: 0 }, "fast");
        return false;
      });

      var $root = $("html, body");
      $("a.anchor").click(function () {
        var href = $.attr(this, "href");
        $root.animate(
          {
            scrollTop: $(href).offset().top,
          },
          500
        );
        return false;
      });

      $(".menu-toggle")
        .unbind("click")
        .bind("click", function () {
          $("#mobilemenu").toggleClass("open");
          setTimeout(function () {
            $("html").toggleClass("mm");
          }, 50);
          return false;
        });
    });
  }
  render() {
    return (
      <>
        <Header />
        <div className="toolbar" id="toolbar">
          <div className="sel-lang">
            <button className="sel-lang__en active">
              <i className="lang_icon">
                <img
                  src="/images/flag_en.png"
                  alt="Английский"
                  title="Английский"
                  width="24"
                  height="24"
                />
              </i>
            </button>
            <button className="sel-lang__ru">
              <i className="lang_icon">
                <img
                  src="/images/flag_ru.png"
                  alt="Русский"
                  title="Русский"
                  width="24"
                  height="24"
                />
              </i>
            </button>
          </div>
          <button className="sel-dark-toggle" id="toggle-darkmod">
            <BsFillMoonFill
              className="i__moon"
              style={{
                color: "white",
                width: "24",
                height: "24",
              }}
            />
            <BsSun
              className="i__sun"
              style={{
                display: "none",
                width: "24",
                height: "24",
                color: "white",
              }}
            />
          </button>
        </div>
      </>
    );
  }
}

function Header() {
  //const size = useScreenSize();
  return (
    <header id="myheader" className="header">
      <div className="wrp-min">
        <div className="header-panel">
          <Logo />
          <Menu />
          <HeadRight />
          <button
            className="menu-toggle menu-butter"
            aria-label="Mobile Menu"
            style={{ display: "none" }}
          >
            <span className="butterbrod">
              <i></i>
              <i></i>
              <i></i>
            </span>
          </button>
        </div>
      </div>
    </header>
  );
}

function HeadRight() {
  const dispatch = useDispatch();
  const toogle = () => {
    dispatch(modalToogle());
  };

  const login = true;

  if (login) {
    return (
      <div className="head-right">
        <div className="userpanel dropdown hmenu-more">
          <button
            className="dropdown-btn"
            data-toggle="dropdown"
            id="login_drop"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <i className="avatar fit-cover">
              <img src="/dleimages/noavatar.webp" alt="yilan53" />
            </i>
          </button>
          <div
            className="dropdown-menu dropdown-form dropdown-menu-right"
            aria-labelledby="login_drop"
            style={{
              display: "none",
            }}
          >
            <div className="login-pane__info">
              <a
                href="https://5play.ru/en/user/yilan53/"
                className="avatar fit-cover"
              >
                <img src="/dleimages/noavatar.webp" alt="yilan53" />
              </a>
              <div className="title">
                <a href="https://5play.ru/en/user/yilan53/">yilan53</a>
              </div>
            </div>
            <ul className="login-pane__menu">
              <li>
                <a href="https://5play.ru/index.php?do=pm&amp;lang=en">
                  PM{" "}
                  <span className="f-right muted">
                    <b>0</b> / 0
                  </span>
                </a>
              </li>
              <li>
                <a href="https://5play.ru/en/favorites/">
                  Bookmarks{" "}
                  <span className="f-right muted">
                    <b>1</b>
                  </span>
                </a>
              </li>
              <li>
                <a href="/index.php?do=lastcomments&amp;lang=en">
                  Last comments
                </a>
              </li>
              <li>
                <a href="https://5play.ru/en/newposts/">Unread</a>
              </li>
              <li>
                <a href="https://5play.ru/index.php?action=logout&amp;lang=en">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
        <button
          style={{
            display: "none",
          }}
          className="q-search-call"
          aria-label="Find"
        >
          <span className="sr-only">Find</span>
          <FcSearch
            className="qs-1"
            style={{
              width: "24",
              height: "24",
            }}
          />
          <MdClose
            className="qs-2"
            style={{
              width: "24",
              height: "24",
            }}
          />
        </button>
      </div>
    );
  }

  return (
    <div className="head-right">
      <button
        onClick={toogle}
        data-toggle="modal"
        data-target="#login"
        className="log-in"
        type="button"
      >
        <span className="sr-only">Authorization</span>
        <FaUser
          style={{
            width: "24",
            height: "24",
          }}
        />
      </button>
      <button
        style={{
          display: "none",
        }}
        className="q-search-call"
        aria-label="Find"
      >
        <span className="sr-only">Find</span>
        <FcSearch
          className="qs-1"
          style={{
            width: "24",
            height: "24",
          }}
        />
        <MdClose
          className="qs-2"
          style={{
            width: "24",
            height: "24",
          }}
        />
      </button>
    </div>
  );
}

function Menu() {
  return (
    <div
      id="mobilemenu"
      className="head-tools"
      style={{
        display: "none",
      }}
    >
      <div className="head-tools-panel">
        <nav
          className="hmenu"
          itemScope
          itemType="http://schema.org/SiteNavigationElement"
        >
          <a href="/android/games/" itemProp="url" className="hm-games">
            <span itemProp="name">
              <i className="s-yellow c-icon">
                <GiGamepad
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    display: "block",
                  }}
                />
              </i>
              Games
            </span>
          </a>
          <a href="/android/apps/" itemProp="url" className="hm-apps">
            <span itemProp="name">
              <i className="s-purple c-icon">
                <MdApps
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    display: "block",
                  }}
                />
              </i>
              Apps
            </span>
          </a>
          <a href="/android/top100/" itemProp="url" className="hm-top">
            <span itemProp="name">
              <i className="s-red c-icon">
                <GiTrophyCup
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    display: "block",
                  }}
                />
              </i>
              TOP 100
            </span>
          </a>
          <a href="/news/" itemProp="url" className="hm-news">
            <span itemProp="name">
              <i className="s-blue c-icon">
                <MdFlashOn
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    display: "block",
                  }}
                />
              </i>
              News
            </span>
          </a>
          <div className="hmenu-more dropdown">
            <button className="hmenu-more-btn">
              <span className="hmenu-more-dots">
                <i></i>
                <i></i>
                <i></i>
              </span>
            </button>
            <div
              style={{
                display: "none",
              }}
              className="dropdown-menu"
            >
              <div className="hmenu-order">
                <a href="/order/" rel="nofollow">
                  Order table
                </a>
                <FcInvite
                  style={{
                    width: "32",
                    height: "32",
                  }}
                />
              </div>
              <nav className="hmenu-sub">
                <a href="/feedback/">Feedback</a>
                <a href="/copyright/">Terms of use</a>
                <a href="/disclaimer/">DMCA</a>
                <a href="/pravoobl/">Copyright Holders</a>
              </nav>
            </div>
          </div>
        </nav>
        <form
          className="q-search"
          style={{
            display: "none",
          }}
          method="post"
        >
          <div className="q-search-box">
            <label className="q-search-label" htmlFor="story">
              Search
            </label>
            <input
              className="q-search-text"
              id="story"
              name="story"
              placeholder="I want to find..."
              type="search"
              autoComplete="off"
            />
          </div>
          <input type="hidden" name="do" value="search" />
          <input type="hidden" name="subaction" value="search" />
        </form>
        <button
          className="menu-toggle menu-close bbcodes"
          aria-label="Menu Close"
          style={{
            display: "none",
          }}
        >
          <MdClose
            style={{
              width: "24",
              height: "24",
            }}
          />
        </button>
      </div>
      <div
        className="menu-toggle mm-overlay"
        style={{
          display: "none",
        }}
      ></div>
    </div>
  );
}
function Logo() {
  //const size = useScreenSize();
  return (
    <a className="logotype" href="/" title="5play">
      <span className="sr-only">5play</span>
      5play
    </a>
  );
}

export default MyHeader;
