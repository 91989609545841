import React from "react";

import {
  FaArrowLeft,
  FaArrowRight,
  FaRegComments,
  FaFlag,
} from "react-icons/fa";
import { BsFillReplyFill } from "react-icons/bs";
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import "../comments.css";

class CommentListWidget extends React.Component {
  render() {
    return <CommentBlock />;
  }
}
function CommentBlock() {
  return (
    <div className="block b-comments">
      <CommentHead />
      <CommentContent />
    </div>
  );
}

function CommentContent() {
  return (
    <div className="b-cont">
      <CommentList />
      <CommentPagition />
    </div>
  );
}

function CommentList() {
  return (
    <form method="post" name="dlemasscomments" id="dlemasscomments">
      <div id="dle-comments-list">
        <div id="dle-ajax-comments"></div>
        <div id="comment"></div>
        <ol className="comments-tree-list">
          <li id="comments-tree-item-185487" className="comments-tree-item">
            <div id="comment-id-185487">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/AndroidGamerZ/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="AndroidGamerZ"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/AndroidGamerZ/"
                    >
                      AndroidGamerZ
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-05-17T20:06">
                      17 May 2022 20:06
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-185487">
                    Break the Bank update is missing in the game.
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/">
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-185487">62</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-185487">15</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-185046" className="comments-tree-item">
            <div id="comment-id-185046">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Cto+to/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="Cto to"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/Cto+to/"
                    >
                      Cto to
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-05-08T19:25">
                      8 May 2022 19:25
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-185046">
                    Что делать если пишет "Uncaught Exception", я уже 3 раз
                    убиваю королеву, подскажите пожалуйста
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-185046">32</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-185046">6</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-184607" className="comments-tree-item">
            <div id="comment-id-184607">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/notsootuff/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="notsootuff"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/notsootuff/"
                    >
                      notsootuff
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-29T17:37">
                      29 April 2022 17:37
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-184607">Why I can't download it?</div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-184607">29</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-184607">8</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-184455" className="comments-tree-item">
            <div id="comment-id-184455">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/%E2%BB%A2%E5%8C%96%E8%85%BE%E2%81%A7%D7%B5%E2%81%A2/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="⻢化腾⁧׵⁢"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/%E2%BB%A2%E5%8C%96%E8%85%BE%E2%81%A7%D7%B5%E2%81%A2/"
                    >
                      ⻢化腾⁧׵⁢
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-27T07:55">
                      27 April 2022 07:55
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-184455">
                    Android 12 can't start the game.
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-184455">46</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-184455">13</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-184370" className="comments-tree-item">
            <div id="comment-id-184370">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/31kspectre/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="31kspectre"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/31kspectre/"
                    >
                      31kspectre
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-25T20:56">
                      25 April 2022 20:56
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-184370">
                    I see users who cant download it. So ik you downloaded but
                    cant install,you will rename the file
                    Dead-cells-2.7.8-mod-t-5playru.apk and download it like that
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-184370">16</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-184370">5</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ol className="comments-tree-list">
              <li id="comments-tree-item-184426" className="comments-tree-item">
                <div id="comment-id-184426">
                  <div className="comment pos-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/WhoAmi/">
                            <img
                              src="/templates/5p/dleimages/noavatar.png"
                              alt="WhoAmi"
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/WhoAmi/"
                        >
                          WhoAmi
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-26T20:02"
                        >
                          26 April 2022 20:02
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-184426">
                        For me the situation is different. When I want to
                        download the APK I'm told that the webpage doesn't
                        exist. I don't know what's going on.
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-184426">12</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-184426">2</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <ol className="comments-tree-list">
                  <li
                    id="comments-tree-item-184487"
                    className="comments-tree-item"
                  >
                    <div id="comment-id-184487">
                      <div className="comment pos-comm">
                        <div className="comment-head">
                          <div className="avatar-status">
                            <i className="avatar fit-cover">
                              <a href="https://5play.ru/en/user/Azazel202/">
                                <img
                                  src="//5play.ru/uploads/fotos/foto_84316.webp"
                                  alt="Azazel202"
                                  loading="lazy"
                                />
                              </a>
                            </i>
                          </div>
                          <span className="name truncate">
                            <a
                              onClick={() => {}}
                              href="https://5play.ru/en/user/Azazel202/"
                            >
                              Azazel202
                            </a>
                          </span>
                          <div className="comment-meta">
                            <span className="group-label g-user">Visitors</span>
                            <time
                              className="date c-muted"
                              dateTime="2022-04-27T17:44"
                            >
                              27 April 2022 17:44
                            </time>
                          </div>
                        </div>
                        <div className="comment-text">
                          <div id="comm-id-184487">
                            for me same just use a vpn to download
                          </div>
                        </div>
                        <div className="comment-foot">
                          <ul className="comment-tools">
                            <li className="com__reply">
                              <a onClick={() => {}} href="/">
                                <span>Reply</span>
                                <BsFillReplyFill size={24} />
                              </a>
                            </li>
                            <li className="com__edit"></li>
                            <li className="com__del"></li>
                            <li className="com__spam"></li>
                            <li className="com__compl">
                              <a href="/)">
                                <FaFlag size={24} />
                              </a>
                            </li>
                          </ul>
                          <div className="com-likes">
                            <a href="/" onClick={() => {}}>
                              <span className="com-like-plus">
                                <MdThumbUp size={24} />+
                                <span id="comments-likes-id-184487">9</span>
                              </span>
                              <span className="sr-only">Like</span>
                            </a>
                            <a href="/" onClick={() => {}}>
                              <span className="com-like-minus">
                                <MdThumbDown size={24} />-
                                <span id="comments-dislikes-id-184487">3</span>
                                <span className="sr-only">Dislike</span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li id="comments-tree-item-184273" className="comments-tree-item">
            <div id="comment-id-184273">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Ryanology/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="Ryanology"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/Ryanology/"
                    >
                      Ryanology
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-23T23:56">
                      23 April 2022 23:56
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-184273">
                    Pls uploaded 32bit apk i need this
                    <br />
                    Plssss
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-184273">19</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-184273">3</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-184043" className="comments-tree-item">
            <div id="comment-id-184043">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/WhoAmi/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="WhoAmi"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/WhoAmi/"
                    >
                      WhoAmi
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-20T15:07">
                      20 April 2022 15:07
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-184043">
                    My phone is 64 bit but I can't download the APK. Did I miss
                    something?
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-184043">13</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-184043">5</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-183863" className="comments-tree-item">
            <div id="comment-id-183863">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/BRIANKANNO/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="BRIANKANNO"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/BRIANKANNO/"
                    >
                      BRIANKANNO
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-18T01:08">
                      18 April 2022 01:08
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-183863">
                    Uncaught Exception
                    <br />
                    Failed to alloc memory page. Breaking before stack overflow
                    <br />
                    Please fix this problem. Thank you
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-183863">15</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-183863">5</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ol className="comments-tree-list">
              <li id="comments-tree-item-184203" className="comments-tree-item">
                <div id="comment-id-184203">
                  <div className="comment neg-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/9322165148/">
                            <img
                              src="/templates/5p/dleimages/noavatar.png"
                              alt="9322165148"
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/9322165148/"
                        >
                          9322165148
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-22T23:12"
                        >
                          22 April 2022 23:12
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-184203">
                        Hey mate Got any solution ??
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-184203">7</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-184203">14</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </li>
          <li id="comments-tree-item-183414" className="comments-tree-item">
            <div id="comment-id-183414">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Hmmm/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="Hmmm"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a onClick={() => {}} href="https://5play.ru/en/user/Hmmm/">
                      Hmmm
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-11T02:26">
                      11 April 2022 02:26
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-183414">
                    why i cant install this mod? is that because i use my arm 32
                    phone?
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-183414">12</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-183414">6</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ol className="comments-tree-list">
              <li id="comments-tree-item-183556" className="comments-tree-item">
                <div id="comment-id-183556">
                  <div className="comment pos-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/Meep/">
                            <img
                              src="//5play.ru/uploads/fotos/foto_76492.webp"
                              alt="Meep"
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/Meep/"
                        >
                          Meep
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-13T14:55"
                        >
                          13 April 2022 14:55
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-183556">
                        I'm pretty sure that it need a 64bit phone i think you
                        have a 32 bit phone. It says arm64 at the top of the
                        game picture
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-183556">6</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-183556">4</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="comments-tree-item-183585" className="comments-tree-item">
                <div id="comment-id-183585">
                  <div className="comment pos-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/Heco./">
                            <img
                              src="//5play.ru/uploads/fotos/foto_80968.webp"
                              alt="Heco."
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/Heco./"
                        >
                          Heco.
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-13T20:58"
                        >
                          13 April 2022 20:58
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-183585">
                        Yeah it won't support maybe because the game is for arm
                        64 but your phone is arm 32.
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-183585">6</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-183585">2</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="comments-tree-item-183990" className="comments-tree-item">
                <div id="comment-id-183990">
                  <div className="comment pos-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/Hmmm/">
                            <img
                              src="/templates/5p/dleimages/noavatar.png"
                              alt="Hmmm"
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/Hmmm/"
                        >
                          Hmmm
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-20T02:28"
                        >
                          20 April 2022 02:28
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-183990">oh ok thanks guys</div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-183990">3</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-183990">2</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </li>
          <li id="comments-tree-item-183299" className="comments-tree-item">
            <div id="comment-id-183299">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Ki%C3%98ver/">
                        <img
                          src="//5play.ru/uploads/fotos/foto_79664.webp"
                          alt="KiØver"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/Ki%C3%98ver/"
                    >
                      KiØver
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-08T21:38">
                      8 April 2022 21:38
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-183299">
                    Cómo hago para actualizar el juego?
                    <br />
                    !!!!!
                    <br />
                    Gente, para los que no saben cómo actualizar el juego,
                    simplemente vuelven a descargar el juego PERO NO ELIMINEN EL
                    QUE TIENEN y así se actualiza el juego con los nuevos datos
                    y el progreso no se pierde.
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-183299">9</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-183299">6</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-183219" className="comments-tree-item">
            <div id="comment-id-183219">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/The+Scarf/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="The Scarf"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/The+Scarf/"
                    >
                      The Scarf
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-04-07T19:38">
                      7 April 2022 19:38
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-183219">
                    There's a bug which makes some of the new features unusable,
                    but I don't know if it's only on pirated versions, or if
                    it's in the original ones too
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-183219">15</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-183219">2</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-181891" className="comments-tree-item">
            <div id="comment-id-181891">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/BennyWhite420/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="BennyWhite420"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/BennyWhite420/"
                    >
                      BennyWhite420
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-03-19T02:18">
                      19 March 2022 02:18
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-181891">
                    How do I remove the 5play ad when I open the game?
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-181891">51</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-181891">27</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ol className="comments-tree-list">
              <li id="comments-tree-item-183586" className="comments-tree-item">
                <div id="comment-id-183586">
                  <div className="comment">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/Heco./">
                            <img
                              src="//5play.ru/uploads/fotos/foto_80968.webp"
                              alt="Heco."
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/Heco./"
                        >
                          Heco.
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-13T20:59"
                        >
                          13 April 2022 20:59
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-183586">
                        The game was modified like that, you have to pass
                        through that to ether play or exit.
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-183586">4</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-183586">4</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </li>
          <li id="comments-tree-item-181728" className="comments-tree-item">
            <div id="comment-id-181728">
              <div className="comment neg-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Unknown3442/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="Unknown3442"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/Unknown3442/"
                    >
                      Unknown3442
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-03-16T13:11">
                      16 March 2022 13:11
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-181728">
                    This game don't connect Play Games. Please, fix this
                    problem. Thanks
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-181728">23</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-181728">32</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ol className="comments-tree-list">
              <li id="comments-tree-item-183526" className="comments-tree-item">
                <div id="comment-id-183526">
                  <div className="comment neg-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/Pakyu09/">
                            <img
                              src="/templates/5p/dleimages/noavatar.png"
                              alt="Pakyu09"
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/Pakyu09/"
                        >
                          Pakyu09
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-13T08:33"
                        >
                          13 April 2022 08:33
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-183526">
                        A f*cking noob you are,modded games cannot connect to
                        google play,use your brain dumb
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-183526">11</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-183526">35</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <ol className="comments-tree-list">
                  <li
                    id="comments-tree-item-183580"
                    className="comments-tree-item"
                  >
                    <div id="comment-id-183580">
                      <div className="comment pos-comm">
                        <div className="comment-head">
                          <div className="avatar-status">
                            <i className="avatar fit-cover">
                              <a href="https://5play.ru/en/user/Heco./">
                                <img
                                  src="//5play.ru/uploads/fotos/foto_80968.webp"
                                  alt="Heco."
                                  loading="lazy"
                                />
                              </a>
                            </i>
                          </div>
                          <span className="name truncate">
                            <a
                              onClick={() => {}}
                              href="https://5play.ru/en/user/Heco./"
                            >
                              Heco.
                            </a>
                          </span>
                          <div className="comment-meta">
                            <span className="group-label g-user">Visitors</span>
                            <time
                              className="date c-muted"
                              dateTime="2022-04-13T20:33"
                            >
                              13 April 2022 20:33
                            </time>
                          </div>
                        </div>
                        <div className="comment-text">
                          <div id="comm-id-183580">
                            You don't need to be rube to say that. Not everybody
                            knows everything. You also happened to don't know it
                            before like everyone.
                          </div>
                        </div>
                        <div className="comment-foot">
                          <ul className="comment-tools">
                            <li className="com__reply">
                              <a onClick={() => {}} href="/">
                                <span>Reply</span>
                                <BsFillReplyFill size={24} />
                              </a>
                            </li>
                            <li className="com__edit"></li>
                            <li className="com__del"></li>
                            <li className="com__spam"></li>
                            <li className="com__compl">
                              <a href="/)">
                                <FaFlag size={24} />
                              </a>
                            </li>
                          </ul>
                          <div className="com-likes">
                            <a href="/" onClick={() => {}}>
                              <span className="com-like-plus">
                                <MdThumbUp size={24} />+
                                <span id="comments-likes-id-183580">17</span>
                              </span>
                              <span className="sr-only">Like</span>
                            </a>
                            <a href="/" onClick={() => {}}>
                              <span className="com-like-minus">
                                <MdThumbDown size={24} />-
                                <span id="comments-dislikes-id-183580">1</span>
                                <span className="sr-only">Dislike</span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li id="comments-tree-item-181410" className="comments-tree-item">
            <div id="comment-id-181410">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/holyean/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="holyean"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/holyean/"
                    >
                      holyean
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-03-10T11:11">
                      10 March 2022 11:11
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-181410">
                    Is it possible to update a version with modmenu?
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-181410">22</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-181410">4</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-179500" className="comments-tree-item">
            <div id="comment-id-179500">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Stinkydinky/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="Stinkydinky"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/Stinkydinky/"
                    >
                      Stinkydinky
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2022-01-29T14:07">
                      29 January 2022 14:07
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-179500">
                    The only issue i have with this is that there is no Queen
                    and the seas DLC otherwise it is a great game :)
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-179500">38</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-179500">13</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-178098" className="comments-tree-item">
            <div id="comment-id-178098">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/Detallio/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="Detallio"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/Detallio/"
                    >
                      Detallio
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2021-12-25T23:10">
                      25 December 2021 23:10
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-178098">So no MOD menu? :(</div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-178098">53</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-178098">22</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-176640" className="comments-tree-item">
            <div id="comment-id-176640">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/FLOKYTrader/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="FLOKYTrader"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/FLOKYTrader/"
                    >
                      FLOKYTrader
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2021-11-30T21:40">
                      30 November 2021 21:40
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-176640">
                    Update the game please with the new content.
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-176640">32</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-176640">16</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-170013" className="comments-tree-item">
            <div id="comment-id-170013">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <a href="https://5play.ru/en/user/ironballzs/">
                        <img
                          src="/templates/5p/dleimages/noavatar.png"
                          alt="ironballzs"
                          loading="lazy"
                        />
                      </a>
                    </i>
                  </div>
                  <span className="name truncate">
                    <a
                      onClick={() => {}}
                      href="https://5play.ru/en/user/ironballzs/"
                    >
                      ironballzs
                    </a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-user">Visitors</span>
                    <time className="date c-muted" dateTime="2021-10-04T20:01">
                      4 October 2021 20:01
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-170013">Update please.</div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-170013">26</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-170013">9</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="comments-tree-item-161047" className="comments-tree-item">
            <div id="comment-id-161047">
              <div className="comment neg-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <img
                        src="/templates/5p/dleimages/noavatar.png"
                        alt="Moses"
                        loading="lazy"
                      />
                    </i>
                  </div>
                  <span className="name truncate">
                    <a href="mailto:moetugadejr@yahoo.com">Moses</a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-guest">Guests</span>
                    <time className="date c-muted" dateTime="2021-07-15T20:45">
                      15 July 2021 20:45
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-161047">
                    Can't scan for malware. Anybody confirm?
                  </div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-161047">14</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-161047">30</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ol className="comments-tree-list">
              <li id="comments-tree-item-183584" className="comments-tree-item">
                <div id="comment-id-183584">
                  <div className="comment pos-comm">
                    <div className="comment-head">
                      <div className="avatar-status">
                        <i className="avatar fit-cover">
                          <a href="https://5play.ru/en/user/Heco./">
                            <img
                              src="//5play.ru/uploads/fotos/foto_80968.webp"
                              alt="Heco."
                              loading="lazy"
                            />
                          </a>
                        </i>
                      </div>
                      <span className="name truncate">
                        <a
                          onClick={() => {}}
                          href="https://5play.ru/en/user/Heco./"
                        >
                          Heco.
                        </a>
                      </span>
                      <div className="comment-meta">
                        <span className="group-label g-user">Visitors</span>
                        <time
                          className="date c-muted"
                          dateTime="2022-04-13T20:55"
                        >
                          13 April 2022 20:55
                        </time>
                      </div>
                    </div>
                    <div className="comment-text">
                      <div id="comm-id-183584">
                        It doesn't has any malware or malicious virus and
                        anti-virus say "can't scan for malware" means it can't
                        scan to look for virus, it doesn't mean that it can't
                        scan because malware blocking them to scan. It just
                        means that it didn't scan for malware. Yeah sometimes
                        it's hard to understand these stuff. If you still have
                        doubts then you can scan that file in
                        https://www.virustotal.com/gui/home/upload , well of
                        course you have to download the file first. Downloading
                        files doesn't do any harm even if they have virus it
                        them. It ether need to be installed or to be
                        executed(which only happens in PC).
                      </div>
                    </div>
                    <div className="comment-foot">
                      <ul className="comment-tools">
                        <li className="com__reply">
                          <a onClick={() => {}} href="/">
                            <span>Reply</span>
                            <BsFillReplyFill size={24} />
                          </a>
                        </li>
                        <li className="com__edit"></li>
                        <li className="com__del"></li>
                        <li className="com__spam"></li>
                        <li className="com__compl">
                          <a href="/)">
                            <FaFlag size={24} />
                          </a>
                        </li>
                      </ul>
                      <div className="com-likes">
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-plus">
                            <MdThumbUp size={24} />+
                            <span id="comments-likes-id-183584">2</span>
                          </span>
                          <span className="sr-only">Like</span>
                        </a>
                        <a href="/" onClick={() => {}}>
                          <span className="com-like-minus">
                            <MdThumbDown size={24} />-
                            <span id="comments-dislikes-id-183584">1</span>
                            <span className="sr-only">Dislike</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </li>
          <li id="comments-tree-item-156199" className="comments-tree-item">
            <div id="comment-id-156199">
              <div className="comment pos-comm">
                <div className="comment-head">
                  <div className="avatar-status">
                    <i className="avatar fit-cover">
                      <img
                        src="/templates/5p/dleimages/noavatar.png"
                        alt="Firas"
                        loading="lazy"
                      />
                    </i>
                  </div>
                  <span className="name truncate">
                    <a href="mailto:firasklali1234@gmail.com">Firas</a>
                  </span>
                  <div className="comment-meta">
                    <span className="group-label g-guest">Guests</span>
                    <time className="date c-muted" dateTime="2021-06-06T21:18">
                      6 June 2021 21:18
                    </time>
                  </div>
                </div>
                <div className="comment-text">
                  <div id="comm-id-156199">Update 1.70.9 please.</div>
                </div>
                <div className="comment-foot">
                  <ul className="comment-tools">
                    <li className="com__reply">
                      <a onClick={() => {}} href="/">
                        <span>Reply</span>
                        <BsFillReplyFill size={24} />
                      </a>
                    </li>
                    <li className="com__edit"></li>
                    <li className="com__del"></li>
                    <li className="com__spam"></li>
                    <li className="com__compl">
                      <a href="/)">
                        <FaFlag size={24} />
                      </a>
                    </li>
                  </ul>
                  <div className="com-likes">
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-plus">
                        <MdThumbUp size={24} />+
                        <span id="comments-likes-id-156199">17</span>
                      </span>
                      <span className="sr-only">Like</span>
                    </a>
                    <a href="/" onClick={() => {}}>
                      <span className="com-like-minus">
                        <MdThumbDown size={24} />-
                        <span id="comments-dislikes-id-156199">14</span>
                        <span className="sr-only">Dislike</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </form>
  );
}

function CommentPagition() {
  return (
    <div className="dle-comments-navigation">
      <div className="navigation">
        <span className="page_prev" title="Предыдущая страница">
          <span>
            <FaArrowLeft size={24} />
            <span className="sr-only">Предыдущая страница</span>
          </span>
        </span>
        <div className="pages">
          <nav className="pages-list">
            <span>1</span> <a href="/">2</a>{" "}
          </nav>
        </div>
        <span className="page_next" title="Следующая страница">
          <a href="/">
            <FaArrowRight size={24} />
            <span className="sr-only">Следующая страница</span>
          </a>
        </span>
      </div>
    </div>
  );
}

function CommentHead() {
  return (
    <div className="b-head">
      <h3 className="section-title">
        <i className="s-purple c-icon">
          <FaRegComments size={24} />
        </i>
        Comments 34
      </h3>
      <a href="#addcom-block" className="btn s-green btn-all anchor">
        <span>Comment on</span>
      </a>
    </div>
  );
}

export default CommentListWidget;
