import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
    websiteInfo: {},
    websiteSettings: {},
    showModal: false,
    showModalUserEdit: false,
  },
  reducers: {
    setWebsiteInfo: (state, action) => {
      state.websiteInfo = action.payload;
    },
    setWebsiteSettings: (state, action) => {
      state.websiteSettings = action.payload;
    },
    modalToogle: (state) => {
      state.showModal = !state.showModal;
    },
    userModalToogle: (state) => {
      state.showModalUserEdit = !state.showModalUserEdit;
    },
  },
});

export const {
  setWebsiteSettings,
  setWebsiteInfo,
  modalToogle,
  userModalToogle,
} = counterSlice.actions;

export const selectCount = (state) => state.counter.value;

export default counterSlice.reducer;
