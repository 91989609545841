import React from "react";
import { MyCFooter } from "./footer";
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import { IoMdStats } from "react-icons/io";
import CommentListWidget from "../widget/commentListWidget";
import TakeComment from "../widget/takeComment";
class NewsView extends React.Component {
  render() {
    return (
      <>
        <SpeedBar />
        <NewsArticle />
        <MyCFooter />
      </>
    );
  }
}

function NewsArticle() {
  return (
    <article
      className="view-news section"
      itemScope
      itemType="http://schema.org/Article"
    >
      <NewsHead />
      <div className="wrp-min block-list">
        <NewsContent />
        <CommentListWidget />
        <TakeComment />
      </div>
    </article>
  );
}

function NewsContent() {
  return (
    <div className="block b-article">
      <div className="img">
        <figure className="fit-cover">
          <img
            src="/uploads/posts/1.webp"
            alt="Xiaomi has released a laser projector for games and watching movies"
            width="640"
            height="400"
          />
        </figure>
      </div>
      <div className="b-cont">
        <div className="text">
          The updated 1S model from 2022 has increased brightness and a special
          gaming mode. Also, the device has an up-to-date list of ports and
          supports alternative ways to control playback.
          <br />
          <br />
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              data-src="/uploads/posts/2022-07/1657791201_1.webp"
              style={{
                maxWidth: "100%",
              }}
              alt="Xiaomi has released a laser projector for games and watching movies"
              src="/uploads/posts/2022-07/1657791201_1.webp"
              className="lazy-loaded"
            />
          </div>
          <br />
          <br />
          The projector can project a 150" diagonal image at 1080p resolution
          with automatic image enhancement and autofocus. The device is based on
          laser fluorescence display (ALPD) technology. The gadget has a maximum
          brightness of 2400 ANSI lumens (the previous model had 2000) and can
          decode video formats 4K and 8K Gamer mode has a minimum signal delay
          of 40ms.
          <br />
          <br />
          The projector sounds through a pair of 10W speakers with Dolby Audio
          and DTS-HD surround sound. Among the available connectors there are
          USB 2.0 and USB 3.0, as well as HDMI (eARC and 2.1). In addition, the
          gadget can be added to the Mijia smart home device system with the
          ability to control it through an application from a smartphone or by
          voice.
          <br />
          <br />
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              data-src="/uploads/posts/2022-07/1657791202_2.webp"
              style={{
                maxWidth: "100%",
              }}
              alt=""
              src="/uploads/posts/2022-07/1657791202_2.webp"
              className="lazy-loaded"
            />
          </div>
          <br />
          <br />
          Xiaomi Laser Projector 1S (2022) can be purchased in China for 5999
          yuan (approximately $892). When it will appear in other regions is
          still unknown.
        </div>
        <div className="read-next" title="Read next">
          <a href="https://5play.ru/en/16849-состоялся-релиз-филиальной-беты-android-13.html">
            Состоялся релиз филиальной беты Android 13
          </a>
        </div>
      </div>
    </div>
  );
}

function NewsHead() {
  const newLocal = "#";
  return (
    <div className="view-news-head dark-head dark-section">
      <div className="wrp-min">
        <div className="vn-head-main">
          <h1 itemProp="name" className="title">
            Xiaomi has released a laser projector for games and watching movies
          </h1>
          <div className="meta muted">
            <time className="meta-date" dateTime="2022-07-14T12:35">
              14-07-2022, 12:35
            </time>
            <div className="meta-view">
              <IoMdStats size={24} />
              335
            </div>
          </div>
        </div>
        <div className="likes">
          <a href={newLocal}>
            <span className="like-plus">
              <MdThumbUp size={24} />+<span id="likes-id-16848">9</span>
            </span>
            <span className="sr-only">Like</span>
          </a>
          <a href={newLocal}>
            <span className="like-minus">
              <MdThumbDown size={24} />-<span id="dislikes-id-16848">3</span>
              <span className="sr-only">Dislike</span>
            </span>
          </a>
        </div>
      </div>
      <div
        className="background"
        style={{
          display: "none",
        }}
      >
        <i className="bg-clouds">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2550 700"
            width="2550"
            height="700"
          >
            <path
              fill="#142636"
              d="M554,100h-4A50,50,0,0,1,550,0h4a50,50,0,0,1,0,100Zm25,600H50a50,50,0,0,1,0-100h78.08a50,50,0,1,0,.11-100H98a50,50,0,0,1,0-100H238a50,50,0,0,0,0-100H194a50,50,0,0,1,0-100h56a50,50,0,0,0,.3-100H238A50,50,0,0,1,238,0H430a50,50,0,0,1,0,100H413.55a50,50,0,1,0,.05,100H630a50,50,0,0,1,0,100H521.55a50,50,0,1,0,0,100H526a50,50,0,0,1,0,100H445.55a50,50,0,0,0,.08,100H579a50,50,0,0,1,0,100Z"
            ></path>
            <path
              fill="#142636"
              d="M2073,700a50,50,0,0,1,0-100h16.43a50,50,0,0,0,0-100H1901a50,50,0,0,1,0-100h216.42a50,50,0,0,0,0-100H2097a50,50,0,0,1-50-50h0a50,50,0,0,1,50-50h252a50,50,0,0,1,0,100h-28a50,50,0,0,0,0,100h24a50,50,0,0,1,0,100h-24a50,50,0,0,0,0,100h179a50,50,0,0,1,0,100Z"
            ></path>
          </svg>
        </i>
      </div>
    </div>
  );
}

function SpeedBar() {
  return (
    <div className="wrp-min speedbar">
      <div className="speedbar-panel">
        <a href="https://5play.ru/en/">5play.ru</a> /{" "}
        <a href="https://5play.ru/en/news/">News</a> / Xiaomi has released a
        laser projector for games and watching movies
      </div>
    </div>
  );
}

export default NewsView;
