import React from "react";
import { FcAndroidOs } from "react-icons/fc";
import { RiShieldFlashFill } from "react-icons/ri";
class ItemWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: props.data.key,
      title: props.data.title,
      new: props.data.new,
      update: props.data.update,
      image: props.data.image,
      link: props.data.link,
      android: props.data.android,
      version: props.data.version,
      categorie: props.data.categorie,
      status: props.data.status,
    };
  }
  render() {
    let headTitle;
    if (this.state.new) {
      headTitle = <span className="label label-new">New</span>;
    } else if (this.state.update) {
      headTitle = <span className="label label-up">Update</span>;
    }
    return (
      <div className="entry entry-app">
        <div className="item">
          {headTitle}
          <figure className="img">
            <img
              src={this.state.image}
              alt={this.state.title}
              width="112"
              height="112"
              loading="lazy"
            />
            <span className="post__modpaid">{this.state.status}</span>
            <span className="post__edit"></span>
          </figure>
          <h2 className="title">
            <a className="item-link" href={this.state.link}>
              <span>{this.state.title}</span>
            </a>
          </h2>
          <span className="genre truncate">{this.state.categorie}</span>
          <ul className="entry-app-info">
            <li>
              <FcAndroidOs
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <span className="truncate">{this.state.android}</span>
            </li>
            <li>
              <RiShieldFlashFill
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <span className="truncate">{this.state.version}</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ItemWidget;
