import React from "react";
import { BiMessageRoundedAdd } from "react-icons/bi";

class TakeComment extends React.Component {
  render() {
    return <TakeCommentBlock />;
  }
}
function TakeCommentBlock() {
  return (
    <form method="post" name="dle-comments-form" id="dle-comments-form">
      <div className="anchor-line">
        <span id="addcom-block"></span>
      </div>
      <div id="addcomment" className="block b-add-comments ignore-select">
        <div className="b-head">
          <h3 className="section-title">
            <i className="s-green c-icon">
              <BiMessageRoundedAdd size={24} />
            </i>
            Take a comment
          </h3>
        </div>
        <div className="b-cont">
          <div className="form-group" id="comment-editor">
            <label className="c-muted" htmlFor="comments">
              Comment text
            </label>
            <div className="bb-editor">
              <textarea
                name="comments"
                id="comments"
                cols="70"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div className="form-submit">
            <button
              className="btn btn-block s-green"
              type="submit"
              name="submit"
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <input type="hidden" name="subaction" value="addcomment" />
      <input type="hidden" name="name" id="name" value="yilan53" />
      <input type="hidden" name="mail" id="mail" value="" />
      <input type="hidden" name="post_id" id="post_id" value="11708" />
      <input
        type="hidden"
        name="user_hash"
        value="31b4395498b609e3c7aa56361d2efa2915430d60"
      />
    </form>
  );
}

export default TakeComment;
