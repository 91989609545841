import React from "react";

import { MyCFooter } from "./footer";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FcSearch } from "react-icons/fc";
import ItemWidget from "../widget/itemWidget";
class SearchPage extends React.Component {
  render() {
    return (
      <>
        <PageHeadCat />
        <PageCatBg />
      </>
    );
  }
}

function PageHeadCat() {
  return (
    <div className="page-head-cat darkmod">
      <div className="wrp-min">
        <div className="head-cat-title">
          <h1 className="title">Site search</h1>
        </div>
      </div>
      <i className="bg-clouds"></i>
    </div>
  );
}

function PageCatCont() {
  return (
    <div className="wrp page-cat-cont">
      <div className="entry-listpage list-c6">
        <SearchWidget />
        <PageContListEnty />
        <Pagination />
      </div>
    </div>
  );
}

function SearchWidget() {
  return (
    <form
      name="fullsearch"
      id="fullsearch"
      action="https://5play.ru/index.php?do=search&amp;lang=en"
      method="post"
    >
      <input type="hidden" name="do" id="do" value="search" />
      <input type="hidden" name="subaction" id="subaction" value="search" />
      <input type="hidden" name="search_start" id="search_start" value="0" />
      <input type="hidden" name="full_search" id="full_search" value="0" />
      <input type="hidden" name="result_from" id="result_from" value="1" />
      <div className="wrp-min">
        <div className="search-box s-green">
          <div className="search-box-field">
            <input
              type="text"
              name="story"
              id="searchinput"
              value="android"
              onchange="document.getElementById('result_from').value = 1"
            />
            <button className="search-box-btn" type="button" name="dosearch">
              <FcSearch
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </button>
          </div>
          <div className="search_result_num">
            Found 60 responses (Query results 1 - 12) :
          </div>
        </div>
      </div>
    </form>
  );
}

function PageContListEnty() {
  const items = [];

  for (let index = 0; index < 10; index++) {
    items.push(
      <ItemWidget
        key={index}
        data={{
          key: index,
          title: "Combat Quest - Archer Action RPG",
          description: "description",
          new: true,
          update: false,
          image: "/uploads/posts/2.webp",
          link: "/",
          android: "5.0",
          version: "1.0",
          categorie: "Action",
          status: "MOD",
        }}
      />
    );
  }

  return items;
}

function Pagination() {
  return (
    <div className="navigation">
      <span className="page_prev" title="Предыдущая страница">
        <span>
          <FaArrowLeft
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <span className="sr-only">Предыдущая страница</span>
        </span>
      </span>
      <div className="pages">
        <nav className="pages-list">
          <span>1</span>{" "}
          <a href="https://5play.ru/en/android/games/page/2/">2</a>{" "}
          <a href="https://5play.ru/en/android/games/page/3/">3</a>{" "}
          <a href="https://5play.ru/en/android/games/page/4/">4</a>{" "}
          <a href="https://5play.ru/en/android/games/page/5/">5</a>{" "}
          <a href="https://5play.ru/en/android/games/page/6/">6</a>{" "}
          <a href="https://5play.ru/en/android/games/page/7/">7</a>{" "}
          <a href="https://5play.ru/en/android/games/page/8/">8</a>{" "}
          <a href="https://5play.ru/en/android/games/page/9/">9</a>{" "}
          <a href="https://5play.ru/en/android/games/page/10/">10</a>{" "}
          <span className="nav_ext">...</span>{" "}
          <a href="https://5play.ru/en/android/games/page/839/">839</a>
        </nav>
      </div>
      <span className="page_next" title="Следующая страница">
        <a href="https://5play.ru/en/android/games/page/2/">
          <FaArrowRight
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <span className="sr-only">Следующая страница</span>
        </a>
      </span>
    </div>
  );
}

function PageCatBg() {
  return (
    <div className="page-cat-bg page-search-bg">
      <PageCatCont />
      <MyCFooter />
    </div>
  );
}

export default SearchPage;
