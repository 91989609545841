import React from "react";

import { MyCFooter } from "./footer";
import {
  FaRegComments,
  FaTelegramPlane,
  FaArrowLeft,
  FaArrowRight,
  FaFlag,
} from "react-icons/fa";
import { BsFillReplyFill } from "react-icons/bs";
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import BackgroundOne from "../widget/backroundOne";
class AllCommentsList extends React.Component {
  render() {
    return (
      <>
        <PageHeadTop />
        <PageCatBg />
      </>
    );
  }
}

function PageHeadTop() {
  return (
    <div className="page-sys">
      <section className="section">
        <div className="wrp-min block-list">
          <div className="block b-comments">
            <div class="b-head">
              <h1 class="section-title">
                <i class="s-purple c-icon">
                  <FaRegComments size={24} />
                </i>
                Comments
              </h1>
              <a
                className="btn btn-telegram"
                href="https://t.me/android_5play"
                target={"_blank"}
                rel="noreferrer"
              >
                <i class="c-icon s-telegram">
                  <FaTelegramPlane size={24} />
                </i>
                <span>Our telegram</span>
              </a>
            </div>
            <PageCatCont />
          </div>
        </div>
      </section>
    </div>
  );
}

function PageCatCont() {
  return (
    <section className="b-cont">
      <form method="post" name="dlemasscomments" id="dlemasscomments">
        <div className="dle-comments-list">
          <PageContListEnty />
        </div>
      </form>
      <div class="dle-comments-navigation">
        <div class="navigation">
          <span class="page_prev" title="Предыдущая страница">
            <span>
              <FaArrowLeft size={24} />
              <span class="sr-only">Предыдущая страница</span>
            </span>
          </span>
          <div class="pages">
            <nav class="pages-list">
              <span>1</span>{" "}
              <a href="https://5play.ru/index.php?cstart=2&amp;do=lastcomments&amp;lang=en#comment">
                2
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=3&amp;do=lastcomments&amp;lang=en#comment">
                3
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=4&amp;do=lastcomments&amp;lang=en#comment">
                4
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=5&amp;do=lastcomments&amp;lang=en#comment">
                5
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=6&amp;do=lastcomments&amp;lang=en#comment">
                6
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=7&amp;do=lastcomments&amp;lang=en#comment">
                7
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=8&amp;do=lastcomments&amp;lang=en#comment">
                8
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=9&amp;do=lastcomments&amp;lang=en#comment">
                9
              </a>{" "}
              <a href="https://5play.ru/index.php?cstart=10&amp;do=lastcomments&amp;lang=en#comment">
                10
              </a>{" "}
              <span class="nav_ext">...</span>{" "}
              <a href="https://5play.ru/index.php?cstart=1782&amp;do=lastcomments&amp;lang=en#comment">
                1782
              </a>
            </nav>
          </div>
          <span class="page_next" title="Следующая страница">
            <a href="https://5play.ru/index.php?cstart=2&amp;do=lastcomments&amp;lang=en#comment">
              <FaArrowRight size={24} />
              <span class="sr-only">Следующая страница</span>
            </a>
          </span>
        </div>
      </div>
    </section>
  );
}

function PageContListEnty() {
  const items = [];

  for (let index = 0; index < 20; index++) {
    items.push(
      <div id="comment-id-189120">
        <div class="comment">
          <div class="comment-head">
            <div class="avatar-status">
              <i class="avatar fit-cover">
                <a href="https://5play.ru/en/user/buddha+botak/">
                  <img
                    src="/dleimages/noavatar.webp"
                    alt="buddha botak"
                    loading="lazy"
                  />
                </a>
              </i>
            </div>
            <span class="name truncate">
              <a
                onclick="ShowProfile('buddha+botak', 'https://5play.ru/en/user/buddha+botak/', '0'); return false;"
                href="https://5play.ru/en/user/buddha+botak/"
              >
                buddha botak
              </a>
            </span>
            <div class="comment-meta">
              <span class="group-label g-user">Visitors</span>
              <time class="date c-muted" datetime="2022-07-16T05:40">
                Today, 05:40
              </time>
            </div>
          </div>
          <div class="comment-text">
            <h3 class="comment-title">
              <a href="https://5play.ru/en/1875-summoners-greed.html#comment">
                Summoner's Greed: Endless Idle TD Heroes
              </a>
            </h3>
            <div id="comm-id-189120">why I can't install the apk?</div>
          </div>
          <div class="comment-foot">
            <ul class="comment-tools">
              <li class="com__reply">
                <a href="/">
                  <span>Reply</span>
                  <BsFillReplyFill size={24} />
                </a>
              </li>
              <li class="com__edit"></li>
              <li class="com__del"></li>
              <li class="com__spam"></li>
              <li class="com__compl">
                <a href="/">
                  <FaFlag size={24} />
                </a>
              </li>
            </ul>
            <div class="com-likes">
              <a href="/">
                <span class="com-like-plus">
                  <MdThumbUp size={24} />+
                  <span id="comments-likes-id-189120">0</span>
                </span>
                <span class="sr-only">Like</span>
              </a>
              <a href="/">
                <span class="com-like-minus">
                  <MdThumbDown size={24} />-
                  <span id="comments-dislikes-id-189120">0</span>
                  <span class="sr-only">Dislike</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return items;
}

function PageCatBg() {
  return (
    <>
      <MyCFooter />
      <BackgroundOne />
    </>
  );
}

export default AllCommentsList;
