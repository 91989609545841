import React from "react";

import { MyCFooter } from "./footer";
import { IoMdStats } from "react-icons/io";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
class NewsPage extends React.Component {
  render() {
    return (
      <>
        <SpeedBar />
        <PageHeadCat />
        <PageCatBg />
      </>
    );
  }
}

function SpeedBar() {
  return (
    <div className="wrp-min speedbar">
      <div className="speedbar-panel">
        <a href="https://5play.ru/en/">5play.ru</a> / News
      </div>
    </div>
  );
}

function PageHeadCat() {
  return (
    <div className="page-head-cat">
      <div className="wrp-min">
        <div className="head-cat-title">
          <h1 className="title">News</h1>
        </div>
      </div>
      <i className="bg-clouds"></i>
    </div>
  );
}

function PageCatCont() {
  return (
    <div className="wrp-min page-cat-cont page-news">
      <PageContListEnty />
      <Pagination />
    </div>
  );
}

function PageContListEnty() {
  const items = [];

  for (let index = 0; index < 10; index++) {
    items.push(
      <div className="entry entry-news">
        <div className="item">
          <div className="pic">
            <figure className="fit-cover">
              <img
                src="/uploads/posts/1.webp"
                alt="Android 13 branch beta released"
                width="300"
                height="300"
                loading="lazy"
              />
            </figure>
          </div>
          <div className="cont">
            <div className="meta muted">
              <time className="meta-date" dateTime="2022-07-14T12:40">
                Today, 12:40
              </time>
              <div className="meta-view">
                <IoMdStats
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
                114
              </div>
            </div>
            <h2 className="title">
              <a
                className="item-link"
                href="https://5play.ru/en/16849-android-13-branch-beta-released.html"
              >
                <span>Android 13 branch beta released</span>
              </a>
            </h2>
            <div
              className="description"
              style={{
                display: "none",
              }}
            >
              Google presented the fourth and final version of the test version
              of its new operating system. It is available for download for
              owners of Pixel 4/4XL and above.
            </div>
            <div className="read-more">
              <a href="https://5play.ru/en/16849-android-13-branch-beta-released.html">
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return items;
}

function Pagination() {
  return (
    <div className="navigation">
      <span className="page_prev" title="Предыдущая страница">
        <span>
          <FaArrowLeft
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <span className="sr-only">Предыдущая страница</span>
        </span>
      </span>
      <div className="pages">
        <nav className="pages-list">
          <span>1</span>{" "}
          <a href="https://5play.ru/en/android/games/page/2/">2</a>{" "}
          <a href="https://5play.ru/en/android/games/page/3/">3</a>{" "}
          <a href="https://5play.ru/en/android/games/page/4/">4</a>{" "}
          <a href="https://5play.ru/en/android/games/page/5/">5</a>{" "}
          <a href="https://5play.ru/en/android/games/page/6/">6</a>{" "}
          <a href="https://5play.ru/en/android/games/page/7/">7</a>{" "}
          <a href="https://5play.ru/en/android/games/page/8/">8</a>{" "}
          <a href="https://5play.ru/en/android/games/page/9/">9</a>{" "}
          <a href="https://5play.ru/en/android/games/page/10/">10</a>{" "}
          <span className="nav_ext">...</span>{" "}
          <a href="https://5play.ru/en/android/games/page/839/">839</a>
        </nav>
      </div>
      <span className="page_next" title="Следующая страница">
        <a href="https://5play.ru/en/android/games/page/2/">
          <FaArrowRight
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <span className="sr-only">Следующая страница</span>
        </a>
      </span>
    </div>
  );
}

function PageCatBg() {
  return (
    <div className="page-cat-bg">
      <PageCatCont />
      <MyCFooter />
      <div
        className="background bg-style-1"
        style={{
          display: "none",
        }}
      >
        <i className="bg-circle-purple"></i>
        <i className="bg-circle-yellow bgc-1"></i>
        <i className="bg-circle-yellow bgc-2"></i>
        <i className="bg-circle-green"></i>
        <i className="bg-clouds">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2808 800"
            width="2808"
            height="800"
          >
            <path
              className="cloud-c1"
              d="M2066,300h-2a50,50,0,0,0,0,100h2a50,50,0,0,0,0-100Zm459.27-100H2758a50,50,0,0,0,0-100H2577.58a50,50,0,0,1,0-100H2126.45a50,50,0,0,1-.06,100H1970a50,50,0,0,0,0,100h232.45a50,50,0,0,1-.08,100H2186a50,50,0,0,0,0,100h104.46a50,50,0,0,1,0,100H2282a50,50,0,0,0,0,100h428a50,50,0,0,0,0-100H2601.55a50,50,0,0,1-.3-100H2606a50,50,0,0,0,0-100h-80.45a50,50,0,1,1-.28-100Z"
            ></path>
            <path
              className="cloud-c2"
              d="M2142,324H1969.55a50,50,0,0,1,0-100H1998a50,50,0,0,0,0-100H1770a50,50,0,0,0,0,100h14.26a50,50,0,1,1,0,100H1718a50,50,0,0,0,0,100h424a50,50,0,0,0,0-100Zm132-200H2170a50,50,0,0,0,0,100h104a50,50,0,0,0,0-100Z"
            ></path>
            <path
              className="cloud-c1"
              d="M962,100H781.58a50,50,0,0,1,0-100H206.45a50,50,0,0,1-.06,100H50a50,50,0,0,0,0,100H282.45a50,50,0,0,1-.08,100H266a50,50,0,0,0,0,100H370.46a50,50,0,1,1,0,100H362a50,50,0,0,0,0,100h8.46a50,50,0,1,1,0,100h-24a50,50,0,0,0,0,100H590a50,50,0,0,0,0-100H509.55a50,50,0,1,1,.08-100H790a50,50,0,0,0,0-100H681.55a50,50,0,0,1-.3-100H686a50,50,0,0,0,0-100H605.55a50,50,0,1,1-.28-100H962a50,50,0,0,0,0-100Zm168,0h-28a50,50,0,0,0,0,100h28a50,50,0,0,0,0-100Z"
            ></path>
            <path
              className="cloud-c2"
              d="M1086,244H913.57a50,50,0,0,1,0-100H962a50,50,0,0,0,0-100H654a50,50,0,0,0,0,100h28a50,50,0,0,1,0,100H642a50,50,0,0,0,0,100h444a50,50,0,0,0,0-100Z"
            ></path>
          </svg>
        </i>
      </div>
    </div>
  );
}

export default NewsPage;
