import React from "react";

import { FcSettings } from "react-icons/fc";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MyCFooter } from "./footer";
import ItemWidget from "../widget/itemWidget";
import BackgroundOne from "../widget/backroundOne";
class GameListPage extends React.Component {
  render() {
    return (
      <>
        <SpeedBar />
        <PageHeadCat />
        <PageCatBg />
      </>
    );
  }
}

function PageCatCont() {
  return (
    <div className="wrp page-cat-cont">
      <div className="entry-listpage list-c6">
        <PageContListEnty />
      </div>
      <div className="wrp-min">
        <Pagination />
      </div>
    </div>
  );
}

function PageContListEnty() {
  const items = [];

  for (let index = 0; index < 18; index++) {
    items.push(
      <ItemWidget
        key={index}
        data={{
          key: index,
          title: "Combat Quest - Archer Action RPG",
          description: "description",
          new: true,
          update: false,
          image: "/uploads/posts/2.webp",
          link: "/",
          android: "5.0",
          version: "1.0",
          categorie: "Action",
          status: "MOD",
        }}
      />
    );
  }

  return items;
}

function Pagination() {
  return (
    <div className="navigation">
      <span className="page_prev" title="Предыдущая страница">
        <span>
          <FaArrowLeft
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <span className="sr-only">Предыдущая страница</span>
        </span>
      </span>
      <div className="pages">
        <nav className="pages-list">
          <span>1</span>{" "}
          <a href="https://5play.ru/en/android/games/page/2/">2</a>{" "}
          <a href="https://5play.ru/en/android/games/page/3/">3</a>{" "}
          <a href="https://5play.ru/en/android/games/page/4/">4</a>{" "}
          <a href="https://5play.ru/en/android/games/page/5/">5</a>{" "}
          <a href="https://5play.ru/en/android/games/page/6/">6</a>{" "}
          <a href="https://5play.ru/en/android/games/page/7/">7</a>{" "}
          <a href="https://5play.ru/en/android/games/page/8/">8</a>{" "}
          <a href="https://5play.ru/en/android/games/page/9/">9</a>{" "}
          <a href="https://5play.ru/en/android/games/page/10/">10</a>{" "}
          <span className="nav_ext">...</span>{" "}
          <a href="https://5play.ru/en/android/games/page/839/">839</a>
        </nav>
      </div>
      <span className="page_next" title="Следующая страница">
        <a href="https://5play.ru/en/android/games/page/2/">
          <FaArrowRight
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <span className="sr-only">Следующая страница</span>
        </a>
      </span>
    </div>
  );
}

function PageCatBg() {
  return (
    <div className="page-cat-bg">
      <PageCatCont />
      <MyCFooter />
      <BackgroundOne />
    </div>
  );
}

function PageHeadCat() {
  return (
    <div className="page-head-cat">
      <div className="wrp-min">
        <div className="head-cat-title">
          <h1 className="title">Games for android</h1>
          <div className="head-cat-tools">
            <button
              className="cat-menu-btn"
              type="button"
              title="Select a category"
              data-toggle="collapse"
              data-target="#collapse-menu"
              aria-expanded="true"
            >
              <FcSettings
                style={{
                  width: "24",
                  height: "24",
                }}
              />
              <span className="sr-only">Select a category</span>
            </button>
          </div>
        </div>
        <div
          className="cat-menu-collapse collapse in"
          id="collapse-menu"
          aria-expanded="true"
        >
          <div className="cat-menu-list">
            <ul>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/arcade/">Arcade</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/trivia/">Trivia</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/puzzle/">Puzzle</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/action/">Action</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/racing/">Racing</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/casino/">Casino</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/casual/">Casual</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/card/">Card</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/music/">Music</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/board/">Board</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/educational/">
                  Educational
                </a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/adventure/">
                  Adventure
                </a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/role-playing/">
                  Role playing
                </a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/strategy/">
                  Strategy
                </a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/simulation/">
                  Simulation
                </a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/word/">Word</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/sports/">Sports</a>
              </li>
              <li className="catalog-genres-item">
                <a href="https://5play.ru/en/android/games/family/">Family</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <i className="bg-clouds"></i>
    </div>
  );
}

function SpeedBar() {
  return (
    <div className="wrp-min speedbar">
      <div className="speedbar-panel">
        <a href="https://5play.ru/en/">5play.ru</a> /{" "}
        <a href="https://5play.ru/en/android/">Android</a> / Games
      </div>
    </div>
  );
}

export default GameListPage;
