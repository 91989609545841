import React from "react";
import BackgroundOne from "../widget/backroundOne";

import { MyCFooter } from "./footer";
class CopyRight extends React.Component {
  render() {
    return (
      <>
        <PageCatCont />
        <MyCFooter />
        <BackgroundOne />
      </>
    );
  }
}

function PageCatCont() {
  return (
    <div className="page-sys">
      <section className="section">
        <div className="wrp-min block-list">
          <div className="block static-page">
            <div className="b-cont">
              <h1 className="title">Terms of use</h1>
              <div className="text">
                <p>
                  <b>Terms of use</b>
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Your use of this Site means that you accept these terms.
                  <br />
                  If you do not agree to these terms, do not use this Site.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CopyRight;
