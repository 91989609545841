import React from "react";
import { FcAndroidOs } from "react-icons/fc";
import { RiShieldFlashFill } from "react-icons/ri";
class RecommentItemView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: props.data.key,
      title: props.data.title,
      new: props.data.new,
      update: props.data.update,
      image: props.data.image,
      link: props.data.link,
      android: props.data.android,
      version: props.data.version,
    };
  }
  render() {
    return (
      <div key={this.state.key} className="entry">
        <div className="item recom-post recom-blue">
          <div className="img">
            <img
              src={this.state.image}
              width="136"
              height="136"
              alt={this.state.title}
              loading="lazy"
            />
          </div>
          <h2 className="title">
            <a className="item-link" href={this.state.link}>
              <span className="truncate">{this.state.title}</span>
            </a>
          </h2>
          <span className="recom-post-vers">
            <FcAndroidOs
              style={{
                width: "24px",
                height: "24px",
              }}
            />
            {this.state.android}
          </span>

          <span className="recom-post-android">
            <RiShieldFlashFill
              style={{
                width: "24px",
                height: "24px",
              }}
            />
            {this.state.version}
          </span>
          <i className="recom-post-bg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 596 300"
              width="596"
              height="300"
              style={{
                minwidth: "596px",
              }}
            >
              <circle fill="#37b3e5" cx="120" cy="172" r="120"></circle>
              <circle
                fill="#c74425"
                cx="324"
                cy="120"
                r="120"
                style={{
                  opacity: "0.5",
                }}
              ></circle>
              <circle
                fill="#fede4a"
                style={{
                  opacity: "0.5",
                }}
                cx="476"
                cy="180"
                r="120"
              ></circle>
            </svg>
          </i>
        </div>
      </div>
    );
  }
}

export default RecommentItemView;
