import React from "react";

import { MyCFooter } from "./footer";
import ItemWidget from "../widget/itemWidget";
class Toop100Page extends React.Component {
  render() {
    return (
      <>
        <PageHeadTop />
        <PageCatBg />
      </>
    );
  }
}

function PageHeadTop() {
  return (
    <div className="page-sys">
      <section className="section">
        <div className="page-head-main">
          <h1 className="title">
            <span className="d-block">TOP 100</span>
          </h1>
          <img
            width="1408"
            height="276"
            src="/images/main_illustration.svg"
            alt=""
          />
        </div>
      </section>
    </div>
  );
}

function PageCatCont() {
  return (
    <section className="wrp section">
      <div className="entry-list list-c6">
        <PageContListEnty />
      </div>
    </section>
  );
}

function PageContListEnty() {
  const items = [];

  for (let index = 0; index < 100; index++) {
    items.push(
      <ItemWidget
        key={index}
        data={{
          key: index,
          title: "Combat Quest - Archer Action RPG",
          description: "description",
          new: true,
          update: false,
          image: "/uploads/posts/2.webp",
          link: "/",
          android: "5.0",
          version: "1.0",
          categorie: "Action",
          status: "MOD",
        }}
      />
    );
  }

  return items;
}

function PageCatBg() {
  return (
    <div>
      <PageCatCont />
      <MyCFooter />
      <div className="background bg-style-1">
        <i className="bg-circle-purple"></i>
        <i className="bg-circle-yellow bgc-1"></i>
        <i className="bg-circle-yellow bgc-2"></i>
        <i className="bg-circle-green"></i>
        <i className="bg-clouds">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2808 800"
            width="2808"
            height="800"
          >
            <path
              className="cloud-c1"
              d="M2066,300h-2a50,50,0,0,0,0,100h2a50,50,0,0,0,0-100Zm459.27-100H2758a50,50,0,0,0,0-100H2577.58a50,50,0,0,1,0-100H2126.45a50,50,0,0,1-.06,100H1970a50,50,0,0,0,0,100h232.45a50,50,0,0,1-.08,100H2186a50,50,0,0,0,0,100h104.46a50,50,0,0,1,0,100H2282a50,50,0,0,0,0,100h428a50,50,0,0,0,0-100H2601.55a50,50,0,0,1-.3-100H2606a50,50,0,0,0,0-100h-80.45a50,50,0,1,1-.28-100Z"
            ></path>
            <path
              className="cloud-c2"
              d="M2142,324H1969.55a50,50,0,0,1,0-100H1998a50,50,0,0,0,0-100H1770a50,50,0,0,0,0,100h14.26a50,50,0,1,1,0,100H1718a50,50,0,0,0,0,100h424a50,50,0,0,0,0-100Zm132-200H2170a50,50,0,0,0,0,100h104a50,50,0,0,0,0-100Z"
            ></path>
            <path
              className="cloud-c1"
              d="M962,100H781.58a50,50,0,0,1,0-100H206.45a50,50,0,0,1-.06,100H50a50,50,0,0,0,0,100H282.45a50,50,0,0,1-.08,100H266a50,50,0,0,0,0,100H370.46a50,50,0,1,1,0,100H362a50,50,0,0,0,0,100h8.46a50,50,0,1,1,0,100h-24a50,50,0,0,0,0,100H590a50,50,0,0,0,0-100H509.55a50,50,0,1,1,.08-100H790a50,50,0,0,0,0-100H681.55a50,50,0,0,1-.3-100H686a50,50,0,0,0,0-100H605.55a50,50,0,1,1-.28-100H962a50,50,0,0,0,0-100Zm168,0h-28a50,50,0,0,0,0,100h28a50,50,0,0,0,0-100Z"
            ></path>
            <path
              className="cloud-c2"
              d="M1086,244H913.57a50,50,0,0,1,0-100H962a50,50,0,0,0,0-100H654a50,50,0,0,0,0,100h28a50,50,0,0,1,0,100H642a50,50,0,0,0,0,100h444a50,50,0,0,0,0-100Z"
            ></path>
          </svg>
        </i>
      </div>
    </div>
  );
}

export default Toop100Page;
