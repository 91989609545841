export const ErrorPage = () => {
  return (
    <div>
      <div>
        <h1>404</h1>
        <p>Page not found</p>
      </div>
    </div>
  );
};
