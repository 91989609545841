// import { Container, Col, Row } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { useScreenSize } from "../utils/screen_size";
import { FaFire } from "react-icons/fa";
import { GiGamepad } from "react-icons/gi";
import MyFooter from "../homeFooter";
import BackgroundOne from "../widget/backroundOne";
import ItemWidget from "../widget/itemWidget";
import RecommentItemView from "../widget/recommentItemView";
export const Home = (props) => {
  //   const websiteInfo = useSelector((state) => state.counter.websiteInfo);
  //   const screenSize = useScreenSize();

  return (
    <>
      <PageHeadMain />
      <SectionRecommend />
      <NewGames />
      <NewApp />
      <BackgroundOne />
      <MyFooter />
    </>
  );
};

function NewGames() {
  return (
    <div className="wrp section">
      <div className="section-head">
        <h3 className="section-title">
          <i className="s-yellow c-icon">
            <GiGamepad
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </i>
          New games
        </h3>
        <a className="btn s-green btn-all" href="/en/android/igry/">
          <span>All games</span>
        </a>
      </div>
      <div className="entry-list list-c6">
        <NewGameEntery />
      </div>
    </div>
  );
}

function NewApp() {
  return (
    <div className="wrp section">
      <div className="section-head">
        <h3 className="section-title">
          <i className="s-purple c-icon">
            <GiGamepad
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </i>
          All Apps
        </h3>
        <a className="btn s-green btn-all" href="/en/android/igry/">
          <span>All games</span>
        </a>
      </div>
      <div className="entry-list list-c6">
        <NewGameEntery />
      </div>
    </div>
  );
}

function NewGameEntery() {
  const forloop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const listItems = forloop.map((number) => (
    <ItemWidget
      key={number}
      data={{
        key: number,
        title: "Combat Quest - Archer Action RPG",
        description: "description",
        new: true,
        update: false,
        image: "/uploads/posts/2.webp",
        link: "/",
        android: "5.0",
        version: "1.0",
        categorie: "Action",
        status: "MOD",
      }}
    />
  ));

  return listItems;
}

function PageHeadMain() {
  return (
    <div className="page-head-main">
      <h1 className="title">Games and apps for Android</h1>
      <img
        width="1408"
        height="276"
        src="/images/main_illustration.svg"
        alt=""
      />
    </div>
  );
}

function SectionRecommend() {
  return (
    <>
      <section className="wrp section section-recom">
        <h3 className="section-title">
          <i className="s-green c-icon">
            <FaFire
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </i>
          Recommend
        </h3>
        <div className="scroll-entry-list">
          <div className="entry-list recom-list list-c4">
            <RecommentEntery />
          </div>
        </div>
      </section>
    </>
  );
}

function RecommentEntery() {
  const forloop = [1, 2, 3, 4];
  const listItems = forloop.map((number) => (
    <RecommentItemView
      key={number}
      data={{
        key: number,
        title: "Combat Quest - Archer Action RPG",
        image: "/uploads/posts/" + number + ".webp",
        link: "/",
        android: "5.0",
        version: "1.0",
      }}
    />
  ));

  return listItems;
}
