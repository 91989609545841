import { BrowserRouter, Route, Routes } from "react-router-dom";
import FeedBackPage from "./pages/feedback";
import AppsListPage from "./pages/appsListPage";
import CopyRight from "./pages/copyright";
import DmcaPage from "./pages/disclaimer";
import { ErrorPage } from "./pages/ErrorPage";
import GameListPage from "./pages/gameListPage";
import { Home } from "./pages/home";
import NewsPage from "./pages/newsPage";
import OrderPage from "./pages/order";
import CopyRightHolders from "./pages/pravoobl";
import Toop100Page from "./pages/top100Page";
import ForgotPassword from "./pages/forgotPassword";
import RegisterPage from "./pages/registerPage";
import SearchPage from "./pages/searchPage";
import ItemView from "./pages/itemWiev";
import NewsView from "./pages/newsView";
import ProfileView from "./pages/profileView";
import AllCommentsList from "./pages/allCommentsList";
import FavoriteItems from "./pages/favoriteItems";
import PrivateMessagePage from "./pages/privateMessagePage";
import DownloadPage from "./pages/downloadPage";

export const MyRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/android/games/" element={<GameListPage />} />
        <Route path="/android/apps/" element={<AppsListPage />} />
        <Route path="/android/top100/" element={<Toop100Page />} />
        <Route path="/news/" element={<NewsPage />} />
        <Route path="/order/" element={<OrderPage />} />
        <Route path="/feedback/" element={<FeedBackPage />} />
        <Route path="/copyright/" element={<CopyRight />} />
        <Route path="/disclaimer/" element={<DmcaPage />} />
        <Route path="/pravoobl/" element={<CopyRightHolders />} />
        <Route path="/forgot-password/" element={<ForgotPassword />} />
        <Route path="/register/" element={<RegisterPage />} />
        <Route path="/search/" element={<SearchPage />} />
        <Route path="/item/1-a.html" element={<ItemView />} />
        <Route path="/news/1-a.html" element={<NewsView />} />
        <Route path="/user/admin" element={<ProfileView />} />
        <Route path="/all-comments" element={<AllCommentsList />} />
        <Route path="/favorite-items" element={<FavoriteItems />} />
        <Route path="/private-message" element={<PrivateMessagePage />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};
