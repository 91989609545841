import React from "react";
import "../form.css";
class ForgotPassword extends React.Component {
  componentDidMount() {
    const headerHide = document.getElementById("myheader");
    headerHide.style.display = "none";
  }

  render() {
    return (
      <>
        <Forgot />
      </>
    );
  }
}

function Forgot() {
  return (
    <section className="page-form">
      <div className="page-form-left">
        <div className="page-form-head">
          <a className="logotype" href="/en/" title="5play">
            <span className="sr-only">5play</span>
            5play
          </a>
          <h1 className="title">Games and apps for Android</h1>
        </div>
        <i className="page-form-bg"></i>
      </div>
      <div className="page-form-right">
        <div className="page-form-right-in">
          <div className="pag-form_head">
            <a className="back_to_main" href="/en/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="24"
                width="24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H6.83l2.88-2.88c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L3.71 11.3c-.39.39-.39 1.02 0 1.41L8.3 17.3c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                ></path>
              </svg>
              Back to the main page
            </a>
          </div>
          <main className="page-form-cont">
            <div className="wrp-form-min">
              <form
                method="post"
                name="dle-lostpassword"
                id="dle-lostpassword"
                action="?do=lostpassword&amp;lang=en"
              >
                <h2 className="heading">Password recovery</h2>
                <div className="form-group">
                  <label className="c-muted" for="lostname">
                    Login
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="lostname"
                    id="lostname"
                    placeholder="Your login or E-Mail on the site"
                    required=""
                  />
                </div>
                <div className="form-submit">
                  <button
                    className="btn s-green btn-block"
                    type="submit"
                    name="submit"
                  >
                    Send
                  </button>
                </div>
                <script
                  src="https://www.google.com/recaptcha/api.js?render=6Lf8P6oUAAAAAMERcNYVii_9PbbwWniPMPf6yqYt"
                  async=""
                  defer=""
                ></script>
                <input
                  name="submit_lost"
                  type="hidden"
                  id="submit_lost"
                  value="submit_lost"
                />
              </form>
              <div></div>
            </div>
          </main>
          <div className="page-form_foot c-muted">
            <div className="copyright">
              2021 © 5play.ru - Games and apps for android
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
