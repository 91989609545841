import React from "react";

import { MyCFooter } from "./footer";
class CopyRightHolders extends React.Component {
  render() {
    return (
      <>
        <PageCatCont />
        <MyCFooter />
        <div className="background bg-style-1">
          <i className="bg-circle-purple"></i>
          <i className="bg-circle-yellow bgc-1"></i>
          <i className="bg-circle-yellow bgc-2"></i>
          <i className="bg-circle-green"></i>
          <i className="bg-clouds">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2808 800"
              width="2808"
              height="800"
            >
              <path
                className="cloud-c1"
                d="M2066,300h-2a50,50,0,0,0,0,100h2a50,50,0,0,0,0-100Zm459.27-100H2758a50,50,0,0,0,0-100H2577.58a50,50,0,0,1,0-100H2126.45a50,50,0,0,1-.06,100H1970a50,50,0,0,0,0,100h232.45a50,50,0,0,1-.08,100H2186a50,50,0,0,0,0,100h104.46a50,50,0,0,1,0,100H2282a50,50,0,0,0,0,100h428a50,50,0,0,0,0-100H2601.55a50,50,0,0,1-.3-100H2606a50,50,0,0,0,0-100h-80.45a50,50,0,1,1-.28-100Z"
              ></path>
              <path
                className="cloud-c2"
                d="M2142,324H1969.55a50,50,0,0,1,0-100H1998a50,50,0,0,0,0-100H1770a50,50,0,0,0,0,100h14.26a50,50,0,1,1,0,100H1718a50,50,0,0,0,0,100h424a50,50,0,0,0,0-100Zm132-200H2170a50,50,0,0,0,0,100h104a50,50,0,0,0,0-100Z"
              ></path>
              <path
                className="cloud-c1"
                d="M962,100H781.58a50,50,0,0,1,0-100H206.45a50,50,0,0,1-.06,100H50a50,50,0,0,0,0,100H282.45a50,50,0,0,1-.08,100H266a50,50,0,0,0,0,100H370.46a50,50,0,1,1,0,100H362a50,50,0,0,0,0,100h8.46a50,50,0,1,1,0,100h-24a50,50,0,0,0,0,100H590a50,50,0,0,0,0-100H509.55a50,50,0,1,1,.08-100H790a50,50,0,0,0,0-100H681.55a50,50,0,0,1-.3-100H686a50,50,0,0,0,0-100H605.55a50,50,0,1,1-.28-100H962a50,50,0,0,0,0-100Zm168,0h-28a50,50,0,0,0,0,100h28a50,50,0,0,0,0-100Z"
              ></path>
              <path
                className="cloud-c2"
                d="M1086,244H913.57a50,50,0,0,1,0-100H962a50,50,0,0,0,0-100H654a50,50,0,0,0,0,100h28a50,50,0,0,1,0,100H642a50,50,0,0,0,0,100h444a50,50,0,0,0,0-100Z"
              ></path>
            </svg>
          </i>
        </div>
      </>
    );
  }
}

function PageCatCont() {
  return (
    <div className="page-sys">
      <section className="section">
        <div className="wrp-min block-list">
          <div className="block static-page">
            <div className="b-cont">
              <h1 className="title">Copyright Holders</h1>
              <div className="text">
                <p>
                  In accordance with <a href="/copyright/">Terms of Use</a> and{" "}
                  <a href="/disclaimer/">DMCA</a>, 5play.ru site does not post
                  materials whose copyrights and related rights to which
                  (including distribution rights) belong to other individuals or
                  legal entities. In the event that such material was placed on
                  the pages of the site, the rightholder has the right to apply
                  to the administration to resolve this issue.
                </p>
                <p>
                  There are several options for resolving the issue:
                  <br />
                  1. Replace the link of an existing application with the
                  application provided by the copyright owner. At the same time,
                  it should represent either a cut-down copy of the full
                  application, or a complete application, but with the presence
                  of advertising.
                  <br />
                  2. Link to the app is replaced by a link to Google Play,
                  Amazon Appstore or the official website of the application.
                  <br />
                  3. Remove all links to the application from the site.
                </p>
                <p>
                  To do this, the copyright holder must confirm its rights to
                  the application posted on the site. There are several ways for
                  this:
                  <br />
                  1. Write the site administration with an E-mail, which is
                  listed on the official website of the application or the
                  company that is engaged in the release of the application.
                  <br />
                  2. Write the site administration with the E-mail, which is
                  listed in the attachment.
                  <br />
                  3. Send scanned documents to the site administration
                  confirming the copyright of the application.
                  <br />
                  4. On the official website of the application or the company
                  that produces the application, you must create a .txt file
                  named 5play, and then send a link to it to the site
                  administration.
                </p>
                <p>
                  To contact the administration of the site, please use the link{" "}
                  <b>Feedback</b>, which is located in the basement of the site.
                </p>
                <p>The site administration will respond within 48 hours.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CopyRightHolders;
